/**
 * Ez a lista tartalmazza az összes támogatott nyelvet a Gokun belül.
 * Ezt a listát kell hővíteni, ha új nyelvet szeretnél felvenni.
 */
export type TLanguage = 'hu' | 'en' | 'de' | 'es' | 'pl' | 'si' | 'rs' | 'sk' | 'ua' | 'ro'

/** Nyelvek listája. */
export type TLanguages = readonly TLanguage[]

/**
 * Adott nyelvi kód eredeti neve.
 * @example
 * hu: 'magyar'
 */
export type TLangTitles = TReadonlyRecord<TLanguage, string>

// eslint-disable-next-line @typescript-eslint/no-use-before-define
export type TTarget = string | ITranslation

export interface ITranslation {
  readonly [propName: string]: TTarget
}

export type TTranslations = {
  [key in TLanguage]?: ITranslation
}

/** A nyelv meg lett változtatva. */
export const TRANSLATE_CHANGED_EVENT = 'Translate:Changed'
/** Új nyelv lett hozzáadva. */
export const TRANSLATE_LOCALE_ADDED_EVENT = 'Translate:Added'

/** A nyelvesítőrendszeren belül történő események lehetséges nevei. */
export type TTranslateEventName = 'change'

/** Nyelv megváltozásakor hívódó callback definíciója. */
export type TLanguageChangeCallback = (lang: TLanguage) => void

/** A különböző, a translator által kilőhető eseményekre feliratkozott callbackek listája. */
export interface ITranslatorEventCallbacks {
  change: TLanguageChangeCallback[]
}

export type TNamespacedTrans = (...keys: (string | number)[]) => string

interface IRow {
  readonly key: string
  readonly value: string
}

export type TRows = readonly IRow[]
