import onUnhandledRejection from './utils/onUnhandledRejection'
import onOrientationChange  from './utils/onOrientationChange'
import onContentLoaded      from './utils/onContentLoaded'
import onResize             from './utils/onResize'
import onScroll             from './utils/onScroll'
import onReady              from './utils/onReady'
import onFocus              from './utils/onFocus'
import onError              from './utils/onError'
import onBlur               from './utils/onBlur'

const globalEvents = {
  onResize,
  onReady,
  onOrientationChange,
  onScroll,
  onContentLoaded,
  onFocus,
  onBlur,
  onError,
  onUnhandledRejection,
  onPopState: () => {}
}

export default globalEvents
