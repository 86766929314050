/**
 * Egy tömb elemeit darabolja fel és ad vissza egy kétdimenziós tömböt.
 * @template T
 * @param {readonly T[]} array - Az alap tömb, amit feldarabolunk.
 * @param {number} [size=1] - Milyen méretű tömböket szeretnénk.
 * @returns {T[][]} A kétdimenziós tömb, amiben a chunkok lesznek.
 */
module.exports.chunk = function chunk (array, size = 1) {
  const chunks = array.reduce((/** @type {T[][]} */ resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return chunks
}
