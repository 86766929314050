/** @const */
const REGEXP_FLAGS = [ 'g', 'm', 'i', 'y', 'u', 's', 'd' ]

/**
 * Meghatározza, hogy `s` valid RegExp.
 * @param {string} s - A megadott string.
 * @returns {boolean} Valid RegExp lett megadva.
 */
module.exports.isRegex = function isRegex (s) {
  // Ha kevesebb, mint 2 / jel van benne akkor false
  if (s.split('/').length <= 2) {
    return false
  }

  // /-rel kezdődik
  if (!s.startsWith('/')) {
    return false
  }

  // Az első és utolsó / nem egymás mellett van
  const firstSlashIndex = s.indexOf('/')

  const lastSlashIndex = s.lastIndexOf('/')

  if (Math.abs(lastSlashIndex - firstSlashIndex) <= 1) {
    return false
  }

  const flags = s.slice(lastSlashIndex + 1)
  const content = s.slice(firstSlashIndex + 1, lastSlashIndex)

  // Az összes flag értéke valid
  if (flags.split('').some((flag) => !REGEXP_FLAGS.includes(flag))) {
    return false
  }

  try {
    // @ts-ignore A példányosítás kell.
    const _ = new RegExp(content, flags)
  }
  catch {
    return false
  }

  return true
}
