import before from './before'

/**
 * Elem beszúrása a parent „gyerekei” elé.
 * @param newNode       - Új elem.
 * @param referenceNode - Hova szúrjuk be.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/prepend}
 */
export default function prepend (newNode: Node, referenceNode: Node): void {
  // Ha üres az elem, akkor simán appendolja magába.
  // eslint-disable-next-line no-restricted-properties -- Itt használható a firstChild
  if (!referenceNode?.firstChild) {
    referenceNode.appendChild(newNode)

    return
  }

  // eslint-disable-next-line no-restricted-properties -- Itt használható a firstChild
  before(newNode, referenceNode.firstChild)
}
