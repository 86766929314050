import isSupported from './utils/isSupported'
import create from './utils/create'

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Testing_media_queries}
 */
const MatchMedia = { isSupported, create }

export default MatchMedia
