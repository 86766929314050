/* eslint-disable @typescript-eslint/no-explicit-any */

// https://reactjs.org/docs/jsx-in-depth.html
// https://reactjs.org/docs/dom-elements.html#html-attributes

import { is } from '../is/is'

import createElements from './utils/createElements'

import type { IProps, TFunction, TReturnHTMLElementType } from './definitions'

/**
 * Ez a függvény JSX → JS átalakítás lelke, mert ennek passzolja át a typescript a paramétereket.
 * @example
 * ```jsx
 * const myDiv = <div className={ styles.mydiv } />
 * // →
 * const myDiv = d('div', { className: styles.mydiv }, null)
 * ```
 * @param element  - A kapott elem, lehet string, akkor node-ot csinál, vagy függvény, akkor azt meghívja.
 * @param props    - JSX property.
 * @param children - Lehetséges gyermekek.
 */
export default function jsxRender<T extends string> (
  element: T | TFunction,
  props: IProps,
  ...children: any[]
): TReturnHTMLElementType<T> {
  if (is.string(element)) {
    return createElements<T>(element, props, children)
  }

  // eslint-disable-next-line no-restricted-syntax -- Meh.
  const newProps = { ...props, ...(is.empty(children) ? {} : { children }) }

  const result = element(newProps)

  return result as TReturnHTMLElementType<T>
}
