/* eslint-disable custom/max-top-level-functions-per-file -- Itt megmaradhatnak a segédfüggvények. */

const { is } = require('../../is/is')
const { times } = require('./times')

/**
 * Meghatározza hogy a range függvényben mennyi elemet kell majd generálni.
 * @param {number} start - A megadott kezdőérték.
 * @param {number } [end] - Az intervallum vége.
 * @param {number} [step=1] - A két szomszédos szám közötti különbség.
 * @returns {number} Az elemszám.
 */
function getRangeIterateeCount (start, end, step = 1) {
  const validStep = step === 0 ? 1 : step // 0-val való osztás miatt, ha step=0 akkor azt egyre kell cserélni

  if (end) {
    return Math.floor(Math.abs((start - end) / validStep))
  }

  return Math.floor(Math.abs(start / validStep))
}

/**
 * Ez a függvény generálja a range-n belüli értékeket.
 * @param {number} index - Az aktuális ciklus indexe.
 * @param {number} start - A megadott kezdő érték.
 * @param {number} [end] - Az intervallum vége.
 * @param {number} [step=1] - A két szomszédos szám közötti különbség.
 * @returns {number} Az érték.
 */
function getRangeIterateeValue (
  index,
  start,
  end,
  step = 1
) {
  const validStart = end ? start : 0

  return validStart + (index * step)
}

/**
 * Számokból álló (pozitív és/vagy negatív) tömböt hoz létre, amely az elejétől a végig halad, de nem tartalmazza az utolsót.
 * A -1 -es lépést akkor használjuk, ha negatív kezdést a vég vagy a lépés nélkül adunk meg.
 * Ha a vége nincs megadva, akkor a kezdőértékkel kezdődik, majd 0-ra állítja.
 *
 * @param {number} start - A kezdőérték, ha nincs megadva `end` akkor a start értéke 0, és az itt megadott érték lesz az end.
 * @param {number} [end] - Az intervallum vége.
 * @param {number} [step=1] - A két szomszédos szám közötti különbség.
 *
 * @returns {number[]} A számokból álló tömb.
 */
module.exports.range = function range (start, end, step = 1) {
  const validStep = is.undefined(end) ? (start < 0 ? -step : step) : step

  const res = times(
    getRangeIterateeCount(start, end, validStep),
    (index) => getRangeIterateeValue(index, start, end, validStep)
  )

  return res
}
