import { is } from '@common/is/is'

import getValueFromNode from './getValueFromNode'

/**
 * Egy HTML elemből kigyűjtjük az összes olyan elemet,
 * aminek van [name] attribútuma és összefűzzük azokat a lehetséges értékükkel.
 * @param el      - A HTML elem, amiből kigyűjtük a többi elemet.
 * @param ignores - Opcionális lista, hogy milyen [name] attribútummal rendelkező elemeket szeretnénk kihagyni.
 */
export default function getAttributeValuePairsFromElement<T extends TObject = TUnknownObject> (
  el: HTMLElement,
  ignores: string[] = []
): T | null {
  const query = [ '[name]', ...ignores.map((ignore) => `:not([name="${ ignore }"])`) ].join('')

  const nodes: HTMLElement[] = Array.from(el.querySelectorAll(query))

  // Ennek az elemnek nincsen egyetlen [name] attribútummal rendelkező gyermeke sem.
  if (is.empty(nodes)) {
    return null
  }

  const result: T = Array.from(nodes).reduce((acc: T, node: HTMLElement) => {
    const name  = node.getAttribute('name') ?? ''
    const value = getValueFromNode(node)

    // @ts-expect-error Feltételezzük, hogyha T generic paramétert jól átpasszolunk, akkor ez a kulcs fel van véve az Object-ben
    acc[name] = value

    return acc
  }, {} as T)

  return result
}
