// Ezzel oldjuk meg, hogy ne kelljen reszponzív designt alkalmaznunk és a grafikusok 1920x1080 -as felbontásra tudjanak tervezni.

import MatchMedia from '../MatchMedia/MatchMedia'
import { is } from '../is/is'

import DOM from '../DOM/DOM'
import { Number } from '../Number/Number'

import globalEvents from '../globalEvents/globalEvents'

import halfPx           from './utils/halfPx'
import getScaleRatio    from './utils/getScaleRatio'
import getElementOffset from './utils/getElementOffset'

// Ezt veszi fix méretnek, és ezt fogja scalelni
export const WANTED_W = 1920
export const WANTED_H = 1080

export const CONTENT_SCALE_EVENT = 'ContentScale:Done'

interface IDimensions {
  readonly wantedW: number
  readonly wantedH: number
}

interface IResponsiveDimensions {
  readonly portrait: IDimensions
  readonly landscape: IDimensions
}

let isAlreadyInitialized = false

export default class ContentScale {
  public static getScaleRatio = getScaleRatio
  public static getElementOffset = getElementOffset

  private static wantedW = WANTED_W
  private static wantedH = WANTED_H

  /**
   * Reszponzív scalezés.
   * @param dimensions - Portrait és landscape nézethez beállítások.
   */
  public static responsive (dimensions: IResponsiveDimensions): void {
    const mql = MatchMedia.create('(orientation: portrait)')

    ContentScale.setByMatchMedia(mql.isMatch(), dimensions)

    mql.addEventListener('change', () => {
      ContentScale.setByMatchMedia(mql.isMatch(), dimensions)
    })
  }

  /**
   * Az szélesség és a magasság beállítása.
   * @param wantedW - Szélesség.
   * @param wantedH - Magasság.
   */
  public static setDimensions (wantedW: number, wantedH: number): void {
    ContentScale.wantedW = wantedW
    ContentScale.wantedH = wantedH
  }

  /**
   * Scalezés indítása.
   * @param width  - A szélesség, amihez aránytartók akarunk lenni.
   * @param height - A magasság, amihez aránytartók akarunk lenni.
   */
  public static scale (width: number = window.innerWidth, height: number = window.innerHeight): void {
    const root = document.querySelector('#root')

    if (!is.instanceOf(root, HTMLElement)) {
      return
    }

    root.style.position = 'relative'

    // Ha a natív dimenziók megegyeznek az általunk megadottal, akkor nem kell scale, csak kifeszítés
    if (width === ContentScale.wantedW && height === ContentScale.wantedH) {
      root.style.top       = '0'
      root.style.left      = '0'
      root.style.width     = '100%'
      root.style.height    = '100%'
      root.style.transform = ''
    }
    // Nem egyezik meg a megadottal: számolni kell scale-t
    else {
      const ratio = Number.parse(getScaleRatio(width, height, ContentScale.wantedW, ContentScale.wantedH))

      root.style.height = `${ ContentScale.wantedH }px`
      root.style.width  = `${ ContentScale.wantedW }px`

      root.style.transformOrigin = '0px 0px'

      // A sima scale jó a scale3d helyett, mert azzal szőrösödött a kép.
      root.style.transform = `translate3d(0,0,0) scale(${ ratio },${ ratio })`

      // A getBoundingClientRect helyett kézzel számoljuk ki az új méreteket
      const scaledHeight = ratio * ContentScale.wantedH
      const scaledWidth  = ratio * ContentScale.wantedW

      // Középre mozgatás
      root.style.top  = halfPx(height, scaledHeight)
      root.style.left = halfPx(width, scaledWidth)
    }

    DOM.dispatch(document, CONTENT_SCALE_EVENT)
  }

  /** Az aktuális szélesség és magasság, amihez aránytartóak vagyunk. */
  public static getDimensions (): IDimensions {
    return { wantedW: ContentScale.wantedW, wantedH: ContentScale.wantedH }
  }

  /** Inicializálás. */
  public static init (): void {
    if (isAlreadyInitialized) {
      return
    }

    globalEvents.onReady((): void => ContentScale.scale())
    globalEvents.onResize((): void => ContentScale.scale())
    globalEvents.onOrientationChange((): void => ContentScale.scale())

    isAlreadyInitialized = true
  }

  /**
   * Az adott orientációhoz beállítjuk a szélességet és magasságot.
   * @param matches    - Match-el az orientációhoz?
   * @param dimensions - A dimenziókat tároló object.
   */
  private static setByMatchMedia (matches: boolean, dimensions: IResponsiveDimensions): void {
    const orientation = matches ? dimensions.portrait : dimensions.landscape
    ContentScale.setDimensions(orientation.wantedW, orientation.wantedH)
  }
}
