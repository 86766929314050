import { baseGlobals } from '@root/typescript/baseGlobals'
import { is } from '@common/is/is'

interface IProps {
  readonly children?: unknown
}

/**
 * Ha ezt használjuk, akkor a renderelő tudja, hogy DocumentFragmentet kell csinálnia belőle.
 * @example <Fragment></Fragment>
 * @param props - A Fragment-bent lévő child-ok.
 */
export default function Fragment (props: IProps = {}): DocumentFragment {
  const frag = document.createDocumentFragment()

  const children = is.array(props.children) ? props.children : [ props.children ]

  /**
   * Egy gyermek feldolgozása.
   * @param child - Egy gyermek.
   */
  function processChild (child: unknown): void {
    if (
      is.instanceOf(child, HTMLElement) ||
      is.instanceOf(child, DocumentFragment) ||
      is.instanceOf(child, SVGSVGElement)
    ) {
      frag.appendChild(child)
    }
    else if (is.string(child) || is.number(child)) {
      const c = is.number(child) ? `${ child }` : child
      const textnode = document.createTextNode(c)

      frag.appendChild(textnode)
    }
    else if (is.array(child)) {
      child.forEach(processChild)
    }
    // null, undefined, false
    else if (is.null(child) || is.undefined(child) || child === false) {
      // { false && <Elem /> } VAGY { x ? <Elem /> : false }
      // { null && <Elem /> } VAGY  { x ? <Elem /> : null }
    }
    else if (baseGlobals.isDev) {
      // eslint-disable-next-line no-console, no-restricted-syntax
      console.error('[JSX-RENDER]', child, 'nem appendelhető! Ismeretlen típus:', typeof child)
    }
  }

  children.forEach(processChild)

  return frag
}
