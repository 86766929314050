import { is } from '@common/is/is'

import { Number } from '@common/Number/Number'

import getScaleRatio from './getScaleRatio'

interface IOffset {
  readonly top: number
  readonly bottom: number
  readonly left: number
  readonly right: number
  readonly width: number
  readonly height: number
}

/**
 * Visszaadja egy elem magasságát, szélességét és pozicióját.
 * @param el         - A vizsgálandó elem.
 * @param scaleRatio - Az aktuális scale, amivel számolunk, default megpróbáljuk mi megállapítani.
 */
export default function getElementOffset (
  el: HTMLElement,
  scaleRatio: string | number = getScaleRatio()
): IOffset {
  const elemRect = el.getBoundingClientRect()

  const root = document.querySelector('div[id="root"]')

  if (is.instanceOf(root, HTMLElement)) {
    const rootRect = root.getBoundingClientRect()
    const ratioNum = 1 / Number.parse(scaleRatio)

    const top    = (elemRect.top - rootRect.top) * ratioNum
    const bottom = (elemRect.bottom - rootRect.bottom) * ratioNum
    const left   = (elemRect.left - rootRect.left) * ratioNum
    const right  = (elemRect.right - rootRect.right) * ratioNum
    const width  = elemRect.width * ratioNum
    const height = elemRect.height * ratioNum

    return {
      top,
      bottom,
      left,
      right,
      width,
      height
    }
  }

  throw new Error('Nincs root id-jű elem a dom-ban.')
}
