import type { TLanguage } from '../definitions'

import languageHandler from './languageHandler'
import localeHandler   from './localeHandler'

declare const __LANGS__: TLanguage[]

const addTranslation = localeHandler.addLocale

const { getLang, init, setLang } = languageHandler

export {
  // Azért exportálom, mert a tesztkörnyezetben, a languagerHandler.init
  // függvény, mindig a jest globals-ában definiált __LANGS__ értékeivel példányosul.
  // Így viszont tudjuk példányosítani „egyedi” nyelvekkel.
  init,
  setLang, getLang, addTranslation
}
export { TRANSLATE_CHANGED_EVENT } from '../definitions'

languageHandler.init(__LANGS__)

export { default as getTransByIdWithFallback } from './getTransByIdWithFallback'
export { default as getTransRaw } from './getTransRaw'
export { default as getTransById } from './getTransById'

export { default as getTrans } from './getTrans'
