/**
 * Csinál egy egyedi listát a megadott kulcs alapján.
 * @template T
 * @template {keyof T} K
 * @param {readonly T[]} list - A lista.
 * @param {readonly K[]} keys - A kulcs.
 * @returns {T[]} A lista egyedi elemei.
 */
module.exports.uniqBy = function uniqBy (list, keys) {
  return list.filter((a, i) => {
    return list.findIndex((tmp) => keys.every((key) => a[key] === tmp[key])) === i
  })
}
