import { is } from '@common/is/is'

/**
 * Egy esemény kilövése egy adott elemről.
 * @param el        - Az elem, amiről ki lesz lőve az esemény.
 * @param eventName - Az esemény neve.
 * @param bubbles   - Bubblingoljon felfelé?
 * @param detail    - Nem kötelezően megadandó adat. Ha van adat, akkor CustomEvent-ként a detail-be kerül.
 */
export default function dispatch ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  el: Element | Document | Window,
  eventName: string,
  bubbles = false,
  detail?: unknown
): void {
  const event = (
    is.undefined(detail)
      ? new Event(eventName, { bubbles })
      : new CustomEvent(eventName, { bubbles, detail })
  )

  // eslint-disable-next-line no-restricted-properties -- Itt használhatjuk.
  el.dispatchEvent(event)
}
