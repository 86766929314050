const { is } = require('../../is/is')

const { flatten } = require('./flatten')

/**
 * @template T
 * @typedef {ReadonlyArray<T | TRecursiveArray<T>>} TRecursiveArray
 */

/**
 * @template T
 * @typedef {T extends (infer U)[] ? TFlat<U> : T} TFlat
 */

/**
 * A megadott `array` tömböt egy dimenziósra alakítja át.
 * @template T
 * @param {TRecursiveArray<T>} array - A megadott tömb.
 * @returns {ReadonlyArray<TFlat<T>>} Az egy dimenziós tömb.
 */
module.exports.flattenDeep = function flattenDeep (array) {
  let result = flatten(array)

  let needFlatten = result.some((item) => {
    return is.array(item)
  })

  while (needFlatten) {
    result = flatten(result)

    needFlatten = result.some((item) => {
      return is.array(item)
    })
  }

  // @ts-expect-error
  return /** @type {ReadonlyArray<TFlat<T>>}*/ result
}
