import log from '../Logger/log'

import getFontFolderName from './utils/getFontFolderName'

type TFonts = readonly string[]

/**
 * Az összes font dinamikus importálása egy lista alapján.
 * @param fonts - A fontlista.
 */
export default function localFontLoader (fonts: TFonts): void {
  const [ html ] = Array.from(document.querySelectorAll('html'))
  const el = html as HTMLElement

  // Az sni-text komponensek addig nem láthatóak, amíg nincs wf-active osztály a html tag-en.
  el.classList.add('wf-active')

  fonts.forEach((font) => {
    const [ fontName ]   = font.split(':')
    const fontFolderName = getFontFolderName(fontName)

    import(
      /* webpackChunkName: "fonts/[request]" */
      `@root/fonts/${ fontFolderName }.css`
    ).catch((error) => log(error))
  })
}
