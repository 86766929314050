type TCallback = (e: ErrorEvent | PromiseRejectionEvent) => void

/**
 * A globális error esetén hívathatjuk meg a callbackünket.
 * Leírásért lásd README!
 * @param callback - Errornál megfutó callback.
 */
export default function onError (callback: TCallback): void {
  window.addEventListener('error', callback)
}
