/**
 * Az adott lista uniqolt változatát adja vissza.
 * @template {ReadonlyArray<any>} T
 * @param {T} param - A lista.
 * @returns {Array<T[number]>} Egyedi lista.
 */
function uniq (param) {
  const res = param.filter((value, index, self) => {
    return self.indexOf(value) === index
  })

  return res
}

module.exports.uniq = uniq
