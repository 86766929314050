import { is } from '@common/is/is'

import getCookieParts from './getCookieParts'

/**
 * Visszaadja, hogy a megadott cookie része-e a névben megadott süti.
 * @param cookie - A cookie string.
 * @param name   - A keresett cookie neve.
 */
export default function hasCookieByName (cookie: string, name: string): boolean {
  const parts = getCookieParts(cookie)

  // Itt elég ezt az egyszerű find-ot használni, nem kell a getAllCookiesAsObject-et használni.
  const foundCookie = parts.find((part) => {
    const [ cookieName ] = part.split('=')

    return cookieName === name
  })

  const hasCookie = is.string(foundCookie)

  return hasCookie
}
