import { is } from '@common/is/is'

import { EContentType } from '@common/http/definitions'

import type { TResult } from './getPostData'

import setCorsOrigin from './setCorsOrigin'

/**
 * Az AJAX request headerjének beállítása.
 * @param xhr                - Az xhr object, aminek állítjuk a header-jét.
 * @param postData           - Az adat, amit posztolni szeretnénk.
 * @param needXrw            - Szükséges-e az X-Requested-With header.
 * @param corsOrigin         - Kellenek CORS-headerek?
 * @param defaultContentType - Alapértelmezett contentType.
 */
export default function setRequestHeaders ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  xhr: XMLHttpRequest,
  postData: TResult,
  needXrw?: boolean,
  corsOrigin?: string,
  defaultContentType = EContentType.FormUrlEncoded
): void {
  if (needXrw) {
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  }

  // [MÁGUS]: eredetileg ez a fejléc csak akkor
  // volt hozzácsatolva, ha volt postData is – és az nem form volt.
  //
  // Ez alapvetően helytelen, hiszen ez a header
  // azt mondja meg a távoli szervernek, hogy a kliens – jelen esetben mi –
  // milyen formátumban várjuk vissza a választ – vagy mi
  // milyen formátumokat ismertünk és képesek vagyunk feldolgozni.
  xhr.setRequestHeader('Accept', EContentType.JSON)

  // Csak akkor kell explicit megadni, ha van küldendő adat
  // és az nem ForData – mert azt a böngésző automatikusan maga (ön) állítja be.
  if (postData && !is.instanceOf(postData, FormData)) {
    xhr.setRequestHeader('Content-type', defaultContentType)
  }

  // FormData küldés esetén (se?) a setRequestHeader('Encoding', 'UTF-8') semmit nem ér, ne használd.

  if (corsOrigin) {
    setCorsOrigin(xhr, corsOrigin)
  }
}
