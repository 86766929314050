import { is } from '@common/is/is'

/** Támogatott a Gamepad API? */
export default function isGamePadSupported (): boolean {
  // eslint-disable-next-line no-restricted-globals -- navigator
  if (is.property(navigator, 'getGamepads')) {
    return true
  }

  if (is.property(window, 'onconnectedgamepad')) {
    return true
  }

  return false
}
