/**
 * Elemek kivágása, és visszaadása.
 * @param el - Elem.
 */
export default function cutChildNodes (el: HTMLElement): DocumentFragment {
  const frag = document.createDocumentFragment()

  /* eslint-disable no-restricted-properties -- Itt használható a firstChild */
  while (el.firstChild) {
    frag.appendChild(el.firstChild)
  }
  /* eslint-enable no-restricted-properties */

  return frag
}
