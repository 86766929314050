import { is } from '@common/is/is'

/**
 * Vizsgáljuk, hogy az elem a DOM-ban van vagy sem.
 * @param el - Az elem, amit vizsgálunk.
 */
export default function isAttachedToDOM<T extends Node> (
  el: T
): boolean {
  if (el.parentNode === document) {
    return true
  }

  if (is.null(el.parentNode)) {
    return false
  }

  return isAttachedToDOM(el.parentNode)
}
