import type { TLanguageValue, TEnabledLanguages, TAvailableLanguage as TAvailableLangeditLanguage } from '@ugyesedni/definitions'

import type { ISesionDigestWithoutLogin } from '../sessionService/definitions'

import type {
  IUser,
  IInstitution,
  IChildWithLevelCheckerInfo
} from '../userService/definitions'

export type { IApp } from '../appService/definitions'

export { APP_DEV_PHASES } from '../appService/definitions'

export {
  ENABLED_LANGUAGES,
  UPDATABLE_APP_KEYS,
  SESSION_COOKIE_NAME
} from '@ugyesedni/definitions'

export type { TLanguageValue, TUpdatableAppKey, IGetPageQueryParam } from '@ugyesedni/definitions'

export type { IHasStoredCard } from '../paymentService/definitions'

export enum EBindValue {
  Session         = 'Session',
  User            = 'user',
  UserIdentifier  = 'UserIdentifier',
  Platform        = 'Platform'
}

export const USER_TYPES = [
  'institution',
  'user', // Egyelőre marad...
  'child',
  'parent',
  'teacher'
] as const

export type TUserType = typeof USER_TYPES[number]

export enum EUserPrefix {
  Institution = '10',
  User        = '20',
  Child       = '40',
  Parent      = '50',
  Teacher     = '60'
}

export const USER_TYPE_PREFIXES: Record<string, TUserType> = {
  [EUserPrefix.Institution]:  'institution',
  [EUserPrefix.User]:         'user',
  [EUserPrefix.Child]:        'child',
  [EUserPrefix.Parent]:       'parent',
  [EUserPrefix.Teacher]:      'teacher'
}

export const SELECTABLE_CHILDREN_GRADES = [
  'kindergarten',
  'first',
  'second',
  'third',
  'fourth'
] as const

export type TSelectableChildrenGrade = typeof SELECTABLE_CHILDREN_GRADES[number]

export const CHILDREN_GRADES = [
  ...SELECTABLE_CHILDREN_GRADES,
  'finished'
] as const

export type TChildrenGrade = typeof CHILDREN_GRADES[number]

export const DIFFICULTIES = [
  'easy',
  'medium',
  'hard'
] as const

export type TDifficulty = typeof DIFFICULTIES[number]

export interface IDatabasePagination {
  readonly offset: number
  readonly limit: number
}

export interface IExtendedChild extends IChildWithLevelCheckerInfo {
  /** Hány el nem kezdett útvonala van az adott gyereknek. */
  readonly notStartedRouteCount: number
}

export interface ILogin {
  /** A felhasználóhoz tartózó appok azonosítói (legacy). */
  readonly ownedAppIds: readonly number[]
  /** A felhaszáló által kedvencnek jelölt appok azonosítói. */
  readonly favouriteAppIds: readonly number[]
  /** A bejelentkezéshez használt promóciós kód. */
  readonly promoCode: string | null
  /** Felhasználó adatai. */
  readonly user: IUser | null
  /** Intézmény adatai. */
  readonly institution: IInstitution | null
  /** Gyerek adatai a kötelező szintfelmérő adatokkal bővítve. */
  readonly child: IExtendedChild | null
  /** Felhasnáló típusa. */
  readonly type: TUserType | null
}

export interface ISubscriptionInfo {
  readonly validUntil: string | null
  readonly canGenerateRoute: boolean
}

export interface ISessionDigest extends ISesionDigestWithoutLogin {
  /** Az adott sessionhöz tartozó bejelentkezés adatai. */
  readonly login: ILogin | null
  readonly subscriptionInfo: ISubscriptionInfo
}

// Egy-egy tanév jellegzetes időpontja. Mivel ez nem generalizálható,
// hiszen minden évben máskor és máskor vannak ezek a pontok,
// ezért itt definiálunk közelítő pontokat.
export const SCHOOL_YEAR_POINT_DATES = [
  /** Második félév kezdete. */
  '01-01',
  /** Nyári szünet kezdete. */
  '06-15',
  /** Első félév kezdete. */
  '09-01'
] as const

export type TSchoolYearPointDate = typeof SCHOOL_YEAR_POINT_DATES[number]

export const YEAR_PERIODS = [
  /** Kivételes eset: hogy óvoda estén egész évről beszélünk szintfelmérőknél. */
  'wholeYear',
  /** Iskolakezdés. */
  'beginning',
  /** Félév. */
  'midterm',
  /** Évvége. */
  'end'
] as const

export type TYearPeriod = typeof YEAR_PERIODS[number]

// Ezen object számontartja, hogy egyes dátumok,
// az évfolyam mely részére – elejére, közepérev vagy végére –,
// vonatkozik, amikor egy-egy szintfelérőt ki kell tölteni.
export const SCHOOL_YEAR_TERM_PERIOD: Record<TSchoolYearPointDate, TYearPeriod> = {
  '01-01': 'midterm',
  '06-15': 'end',
  '09-01': 'beginning'
}

/** @example 2024-09-01 */
export type TSchoolYearTermDate = `${ string }-${ TSchoolYearPointDate }`

export const SUBJECTS = [
  'mathematics',
  'hunLang'
] as const

export type TSubject = typeof SUBJECTS[number]

export interface IInsertedIdResponse {
  /** Az újonnan beszúrt egyed azonosítója. */
  readonly insertedId: number
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
// Azon játékok azonosítói, amelyeknek nincsenek
// pályaválasztói, így ezekre fixen szűrni kell egyes lekérdezésekben.
export const APP_IDS_WITHOUT_LEVEL_SELECTOR = [
  /** Matematika 1. Osztály. */
  46,
  /** Matematika 2. Osztály. */
  48,
  /** Matematika 3. Osztály. */
  49,
  /** Matematika 4. Osztály. */
  50,
  /** Színforma. */
  143
]
/* eslint-enable @typescript-eslint/no-magic-numbers */

export type TAvailabilityWithNumber = Readonly<Record<TEnabledLanguages, number>>

export const LANGUAGES_FLAGS: TAvailabilityWithNumber = {
  hu: 1,
  en: 2,
  es: 4,
  ro: 8,
  de: 16,
  sk: 32
  // 64,
  // ...
}

export const DEFAULT_LANGUAGE_VALUE: TLanguageValue = {
  hu: '',
  en: '',
  es: '',
  ro: '',
  de: '',
  sk: ''
}

export const AVAILABLE_PLATFORMS = [
  /** LEGACY, kvázi játéktér. */
  'legacy',

  /** Csillagsuli. */
  'csillagsuli',

  /** Angol nyelvű web. */
  'starSchool',

  /** Legacy román domain. */
  'softdidactic',

  /** Román domain. */
  'starSchoolRo'
] as const

export type TAvailablePlatform = typeof AVAILABLE_PLATFORMS[number]

export const AVAILABILITY_FLAG_NUMBERS: Readonly<Record<TAvailablePlatform, number>> = {
  legacy:        1,
  csillagsuli:   2,
  starSchool:    4,
  softdidactic:  8,
  starSchoolRo: 16
  //            32,
  //            64,
  //           128,
  //           ...,
}

// Az összes elérhető platform felsorolása, amelyeken elérhetőek az appok modulok szerinti felbontása.
export const MODULEABLE_PLATFORMS = [
  'csillagsuli', 'starSchool'
] as const satisfies TAvailablePlatform[]

export type TModuleablePlatform = typeof MODULEABLE_PLATFORMS[number]

export const AVAILABILITY_NAMES: Readonly<Record<TAvailablePlatform, string>> = {
  legacy:       'jatekter',
  softdidactic: 'softdidactic',
  csillagsuli:  'csillagsuli',
  starSchool:   'starSchool',
  starSchoolRo: 'starSchoolRo'
}

/**
 * A Csillagsuliban elérhető nyelvek.
 * A nyelv a platformhoz kötött jelenleg, tehát a magyar csillagsuli.hu-n csak magyarul elérhető a tartalom.
 */
export const AVAILABLE_LANGUAGES = [
  'hu', 'en', 'ro'
] as const satisfies TAvailableLangeditLanguage[]

export type TAvailableLanguage = typeof AVAILABLE_LANGUAGES[number]

export const PLATFORM_LANGS: Record<TAvailablePlatform, TAvailableLanguage> = {
  legacy:       'hu',
  softdidactic: 'ro',
  csillagsuli:  'hu',
  starSchool:   'en',
  starSchoolRo: 'ro'
}
