import { URI } from '@common/URI/URI'
import { is } from '@common/is/is'

import getCookieParts from './getCookieParts'

/**
 * Visszaadja az összes cookie-t, név és érték alapján egy objectben.
 * @param cookie - A cookie string.
 */
export default function getAllCookiesAsObject (cookie: string): TReadonlyRecord<string, string> {
  const all = getCookieParts(cookie)
    .map((v) => v.split('='))
    .reduce((acc: Record<string, string>, v) => {
      // Mivel vannak olyan attribútumok, ahol nincs kulcshoz tartozó érték
      // lásd: HttpOnly, Secure stb.
      // ezért itt eltörik, ha nem vizsgálunk típusra.

      if (is.string(v[1])) {
        const key   = URI.decodeComponent(v[0].trim())
        const value = URI.decodeComponent(v[1].trim())

        acc[key] = value
      }

      return acc
    }, {})

  return all
}
