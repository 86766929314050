import MatchMedia from '@common/MatchMedia/MatchMedia'

type TCallback = (type: 'portrait' | 'landscape') => void
type TDisconnect = { disconnect: () => void }

/**
 * Az orientáció változásának figyelése.
 * @param callback - A callback, ami lefut, ha változás történik.
 */
export default function onOrientationChange (callback: TCallback): TDisconnect {
  /* EGYELŐRE kikapcsolva, nálam (Zsola) nem működött. 2024.04.01.
  // !!! Csak tényleges „forgatásnál” változik az érték. Pl. window onResize-nél nem változik.

  // Legmodernebb, legkevesebb support.
  // Chrome: 38, Edge: 79, Safari 16.4, FF: 44
  // https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
  if ('orientation' in window.screen) {
    const onOrientationChange = () => {
      const { type } = window.screen.orientation
      const orientation = type.includes('landscape') ? 'landscape' : 'portrait'

      callback(orientation)
    }

    window.screen.orientation.addEventListener('change', () => {
      onOrientationChange()
    })

    return { disconnect: () => window.screen.orientation.removeEventListener('change', onOrientationChange) }
  }
  */

  // Chrome: 9, Edge: 12, Safari: 5.1, FF: 6, Opera: 12, MÉG IE IS: 10
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
  if (MatchMedia.isSupported()) {
    const mm = MatchMedia.create('(orientation: portrait)')

    /** Orientációváltásnál megfutó cb. */
    const onOrientationChange = (): void => { // eslint-disable-line no-restricted-syntax -- Itt megendejük.
      if (mm.isMatch()) {
        callback('portrait')

        return
      }

      callback('landscape')
    }

    mm.addEventListener('change', onOrientationChange)

    return { disconnect: () => mm.removeEventListener('change', onOrientationChange) }
  }

  // Ez elméletileg már deprecated és senki nem támogatja, de mit tudunk csinálni...
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/orientationchange_event

  /** Orientációváltásnál megfutó cb. */
  const onOrientationChange = (): void => { // eslint-disable-line no-restricted-syntax -- Itt megendejük.
    const { innerHeight, innerWidth } = window
    const orientation = innerHeight > innerWidth ? 'portrait' : 'landscape'

    callback(orientation)
  }

  (window as Window).addEventListener('orientationchange', onOrientationChange, false)

  return { disconnect: () => (window as Window).removeEventListener('orientationchange', onOrientationChange, false) }
}
