/**
 * Egy példányosított URL objektumot átalakít szöveggé.
 * @param {URL} url - Az URL objektum.
 * @returns {string} Az URL szövegként.
 */
module.exports.toString = function toString (url) {
  const { protocol } = url // "https:"
  const { hostname } = url // "example.com"
  const { pathname } = url // "/path"
  const { search } = url   // "?name=example"
  const { hash } = url     // "#section"

  const fullUrl = `${ protocol }//${ hostname }${ pathname }${ search }${ hash }`

  return fullUrl
}
