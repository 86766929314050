import { is } from '@common/is/is'

import { Number } from '@common/Number/Number'

/**
 * Meghatározza az egér helyét `elem`-en belül.
 * @param elem  - A megadott elem.
 * @param e     - A kiadott Mouse event.
 * @param scale - A scale értéke.
 */
export default function getMousePositionInElement (
  elem: HTMLElement,
  e: MouseEvent | TouchEvent,
  scale: number | string = 1
): { x: number, y: number } {
  const rect = elem.getBoundingClientRect()

  // Sose vizsgáljunk TouchEvent-re, mert nem touch-os eszközön a TouchEvent not defined.
  const x = is.instanceOf(e, MouseEvent) ? e.clientX : e.touches[0]?.clientX ?? 0
  const y = is.instanceOf(e, MouseEvent) ? e.clientY : e.touches[0]?.clientY ?? 0

  return {
    x: (x - rect.left) / Number.parse(scale),
    y: (y - rect.top)  / Number.parse(scale)
  }
}
