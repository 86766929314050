import { debounce } from '@common/utils/utility'

import type { TDisconnect } from '../definitions'

type TCallback = (width?: number, height?: number) => void

/**
 * Wrapper a ResizeObserver és a resize event fölött.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API}
 * @param callback             - A megadott callback.
 * @param fallbackDebounceTime - Ha nincs ResizeObserver támogatás, akkor ez az idő lesz a debounce idő.
 */
export default function onResize (callback: TCallback, fallbackDebounceTime = 250): TDisconnect {
  // Ha a böngésző támogatja, akkor normál módon példányosítjuk és adjuk vissza.
  if ('ResizeObserver' in window) {
    // eslint-disable-next-line no-restricted-globals
    const observer = new ResizeObserver((entries) => {
      const [ entry ] = entries

      if (entry) {
        callback(entry.contentRect.width, entry.contentRect.height)
      }
    })

    observer.observe(document.body)

    return { disconnect: () => observer.disconnect() }
  }

  /** Itt nem véletlenül van újabb arrow function-be rakva a callback hívása, különben type error. */
  const wrapped = debounce(() => callback(), fallbackDebounceTime);

  // Nincs támogatás, maradunk a hagyományos módnál.
  (window as Window).addEventListener('resize', wrapped, false)

  return { disconnect: () => (window as Window).removeEventListener('resize', wrapped) }
}
