import { is } from '../is/is'

import { URI } from '../URI/URI'

import getAllCookiesAsObject from './utils/getAllCookiesAsObject'
import getCookieValueByName  from './utils/getCookieValueByName'
import getCookieParts        from './utils/getCookieParts'

interface ICookie {
  readonly name: string
  readonly value: string | number | boolean
  readonly daysToLive?: number
  readonly path?: string
  readonly domain?: string
  readonly isSecure?: boolean
  /** @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite#lax}. */
  readonly sameSite?: 'Lax' | 'Strict' | 'None'
}

const ONE_MIN_IN_SECS  = 60
const ONE_HOUR_IN_MINS = 60
const ONE_DAY_IN_HOURS = 24
const ONE_DAY_IN_SECS  = ONE_DAY_IN_HOURS * ONE_HOUR_IN_MINS * ONE_MIN_IN_SECS
const SEPARATOR        = ';'

export default abstract class BaseCookie {
  /**
   * Egy cookie string-ből az összes kulcs-érték pár objectbe pakolása.
   * @param cookie - A cookie string.
   */
  public static getAll (cookie: string): TReadonlyRecord<string, string> {
    return getAllCookiesAsObject(cookie)
  }

  /**
   * Egy cookie értéke, név alapján.
   * @param name   - A név, aminek az értékét keressük.
   * @param cookie - A nyers cookie string.
   */
  public static get (name: string, cookie: string): string | undefined {
    return getCookieValueByName(cookie, name)
  }

  /**
   * A megadott paraméter alapján egy cookie összerakása.
   * @param props - A paraméterek, amikből összeállítjuk a cookie-t.
   */
  public static make (props: ICookie): string[] {
    const cookie = [ `${ props.name }=${ URI.encodeComponent(props.value) }` ]

    if (is.number(props.daysToLive)) {
      cookie.push(`max-age=${ props.daysToLive * ONE_DAY_IN_SECS }`)
    }

    if (is.string(props.path)) {
      cookie.push(`path=${ props.path }`)
    }

    if (is.string(props.domain)) {
      cookie.push(`domain=${ props.domain }`)
    }

    if (is.string(props.sameSite)) {
      cookie.push(`SameSite=${ props.sameSite }`)
    }

    if (props.isSecure) {
      cookie.push('secure')
    }

    return cookie
  }

  /**
   * Egy cookie-nak a max-age és az expires beállítása, hogy attól törlődjön.
   * @param cookie - A cookie string.
   * @param path   - Melyik path-re vonatkozik?
   * @param domain - Melyik domain-re vonatkozik?
   */
  protected static clear (cookie: string, path?: string, domain?: string): string {
    const parts = getCookieParts(cookie)

    if (path) {
      parts.push(`path=${ path }`)
    }

    if (domain) {
      parts.push(`domain=${ domain }`)
    }

    parts.push('max-age=0', 'expires=Thu, 01 Jan 1970 00:00:00 GMT')

    return BaseCookie.parse(parts)
  }

  /**
   * Egy cookie részeket tartalmazó tömbből stringet készítünk.
   * @param cookie - A részeket tartalmazó tömb.
   */
  protected static parse (cookie: string[]): string {
    return cookie.join(SEPARATOR)
  }
}
