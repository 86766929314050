import { is } from '@common/is/is'

const SEPARATOR = ';'

/**
 * Egy adott cookie szétszedése részekre.
 * @param cookie - A cookie string.
 */
export default function getCookieParts (cookie: string): string[] {
  const parts = cookie
    .split(SEPARATOR)
    .filter((part) => !is.empty(part))
    .map((part) => part.trim())

  return parts
}
