// https://github.com/danklammer/bytesize-icons
// https://icons.theforgesmith.com/

import { baseGlobals } from '@root/typescript/baseGlobals'

const DEFAULT_SIZE   = 24
const DEFAULT_STROKE =  2
const DEFAULT_COLOR  = 'currentcolor'
const DEFAULT_FILL   = 'none'

// Nem muszáj a bytesize-icons neveit használni, de a dashelt név kötelező!
// pl.: telephone → phone
export const TYPES = [
  'home', 'user', 'book', 'photo', 'trash', 'edit', 'compose', 'upload', 'download', 'plus',
  'search', 'mail', 'phone', 'reload', 'location', 'info', 'clock', 'cart',
  'sign-in', 'close', 'file', 'checkmark', 'clipboard', 'danger', 'gift',
  'chevron-top', 'chevron-bottom', 'chevron-left', 'chevron-right',
  'caret-top',
  'copy',
  'lightning', 'bookmark', 'menu', 'mobile',
  'play'
] as const

export type TIconType = typeof TYPES[number]

interface IProps {
  readonly type: TIconType | 'custom'
  readonly id?: string
  readonly fill?: string
  readonly color?: string
  readonly viewBox?: string
  readonly path?: string
  readonly size?: number | string
  readonly stroke?: number | string
}

type TMap = {
  readonly [key in TIconType]: string
}

// Ha bővíteni szeretnéd, akkor a TYPES-ban adj egy nevet, majd a bytesize-icons linkről másold ide a struktúrát!
const TYPE_MAP: TMap = {
  home: '<path d="M12 20 L12 30 4 30 4 12 16 2 28 12 28 30 20 30 20 20 Z" />',
  /* eslint-disable @stylistic/js/max-len */
  user: '<path d="M22 11 C22 16 19 20 16 20 13 20 10 16 10 11 10 6 12 3 16 3 20 3 22 6 22 11 Z M4 30 L28 30 C28 21 22 20 16 20 10 20 4 21 4 30 Z" />',
  book: '<path d="M16 7 C16 7 9 1 2 6 L2 28 C9 23 16 28 16 28 16 28 23 23 30 28 L30 6 C23 1 16 7 16 7 Z M16 7 L16 28" />',
  photo: '<path d="M20 24 L12 16 2 26 2 2 30 2 30 24 M16 20 L22 14 30 22 30 30 2 30 2 24" /><circle cx="10" cy="9" r="3" />',
  trash: '<path d="M28 6 L6 6 8 30 24 30 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />',
  edit: '<path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />',
  compose: '<path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />',
  upload: '<path d="M9 22 C0 23 1 12 9 13 6 2 23 2 22 10 32 7 32 23 23 22 M11 18 L16 14 21 18 M16 14 L16 29" />',
  download: '<path d="M9 22 C0 23 1 12 9 13 6 2 23 2 22 10 32 7 32 23 23 22 M11 26 L16 30 21 26 M16 16 L16 30" />',
  plus: '<path d="M16 2 L16 30 M2 16 L30 16" />',
  search: '<circle cx="14" cy="14" r="12" /><path d="M23 23 L30 30" />',
  mail: '<path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />',
  phone: '<path d="M3 12 C3 5 10 5 16 5 22 5 29 5 29 12 29 20 22 11 22 11 L10 11 C10 11 3 20 3 12 Z M11 14 C11 14 6 19 6 28 L26 28 C26 19 21 14 21 14 L11 14 Z" /><circle cx="16" cy="21" r="4" />',
  reload: '<path d="M29 16 C29 22 24 29 16 29 8 29 3 22 3 16 3 10 8 3 16 3 21 3 25 6 27 9 M20 10 L27 9 28 2" />',
  location: '<circle cx="16" cy="11" r="4" /><path d="M24 15 C21 22 16 30 16 30 16 30 11 22 8 15 5 8 10 2 16 2 22 2 27 8 24 15 Z" />',
  info: '<path d="M16 14 L16 23 M16 8 L16 10" /><circle cx="16" cy="16" r="14" />',
  clock: '<circle cx="16" cy="16" r="14" /><path d="M16 8 L16 16 20 20" />',
  cart: '<path d="M6 6 L30 6 27 19 9 19 M27 23 L10 23 5 2 2 2" /><circle cx="25" cy="27" r="2" /><circle cx="12" cy="27" r="2" />',
  close: '<path d="M2 30 L30 2 M30 30 L2 2" />',
  file: '<path d="M6 2 L6 30 26 30 26 10 18 2 Z M18 2 L18 10 26 10" />',
  checkmark: '<path d="M2 20 L12 28 30 4" />',
  clipboard: '<path d="M12 2 L12 6 20 6 20 2 12 2 Z M11 4 L6 4 6 30 26 30 26 4 21 4" />',
  gift: '<path d="M4 14 L4 30 28 30 28 14 M2 9 L2 14 30 14 30 9 2 9 Z M16 9 C 16 9 14 0 8 3 2 6 16 9 16 9 16 9 18 0 24 3 30 6 16 9 16 9" />',
  danger: '<path d="M56.15 53.91H7.85a.66.66 0 01-.57-1l24.15-42.5a.65.65 0 011.14 0l24.15 42.5a.66.66 0 01-.57 1z"></path><path stroke-linecap="round" d="M32 46.77v.67M32 24.02v17.43"></path>',
  'sign-in': '<path d="M3 16 L23 16 M15 8 L23 16 15 24 M21 4 L29 4 29 28 21 28" />',
  'chevron-top': '<path d="M30 20 L16 8 2 20" />',
  'chevron-bottom': '<path d="M30 12 L16 24 2 12" />',
  'chevron-right': '<path d="M12 30 L24 16 12 2" />',
  'chevron-left': '<path d="M20 30 L8 16 20 2" />',
  'caret-top': '<path d="M30 22 L16 6 2 22 Z" />',
  copy: '<path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />',
  lightning: '<path d="M18 13 L26 2 8 13 14 19 6 30 24 19 Z" />',
  bookmark: '<path d="M6 2 L26 2 26 30 16 20 6 30 Z" />',
  menu: '<path d="M4 8 L28 8 M4 16 L28 16 M4 24 L28 24" />',
  mobile: '<path d="M21 2 L11 2 C10 2 9 3 9 4 L9 28 C9 29 10 30 11 30 L21 30 C22 30 23 29 23 28 L23 4 C23 3 22 2 21 2 Z M9 5 L23 5 M9 27 L23 27" />',
  play: '<path d="M10 2 L10 30 24 16 Z" />'
  /* eslint-enable @stylistic/js/max-len */
}

/**
 * SVGIcon komponens factory függvénye.
 * @param props - JSX property-k {@link IProps}.
 */
export default function SVGIcon ( // eslint-disable-line @typescript-eslint/naming-convention
  props: IProps
): SVGSVGElement {
  const size   = props.size ?? DEFAULT_SIZE
  const stroke = props.stroke ?? DEFAULT_STROKE
  const icon   = props.type === 'custom' ? (props.path ?? '') : TYPE_MAP[props.type]

  if (baseGlobals.isDev && !icon) {
    throw new Error(`[SVGIcon] nincs ilyen icon: ${ props.type }`)
  }

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttributeNS(null, 'class', 'svg-icon')
  svg.setAttributeNS(null, 'viewBox', props.viewBox ?? '0 0 32 32')
  svg.setAttributeNS(null, 'width', `${ size }`)
  svg.setAttributeNS(null, 'height', `${ size }`)
  svg.setAttributeNS(null, 'fill', props.fill ?? DEFAULT_FILL)
  svg.setAttributeNS(null, 'stroke', props.color ?? DEFAULT_COLOR)
  svg.setAttributeNS(null, 'stroke-linecap', 'round')
  svg.setAttributeNS(null, 'stroke-linejoin', 'linejoin')
  svg.setAttributeNS(null, 'stroke-width', `${ stroke }`)
  svg.innerHTML = icon

  if (props.id) {
    svg.setAttributeNS(null, 'id', props.id)
  }

  return svg
}
