/* eslint-disable import-x/no-mutable-exports, custom/max-top-level-functions-per-file */

import type { TSuccessCallback } from '../definitions'

/** A success handler, amit felül tudunk csapni. */
let globalSuccessHandler: TSuccessCallback = () => { // eslint-disable-line no-restricted-syntax
  // noop
}

/**
 * Beállíthatjuk, hogy az összes AJAX request esetén mi legyen az automatikusam meghívandó callback, sikeres request esetén.
 * @param handler - A függvény, amivel felül tudjuk csapni a globálisat.
 */
function setGlobalSuccessHandler (handler: TSuccessCallback): void {
  globalSuccessHandler = handler
}

export { globalSuccessHandler, setGlobalSuccessHandler }
