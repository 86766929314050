const { is } = require('../../is/is')

/**
 * Visszaadja egy tömb ismétlődő elemeit.
 * @template T
 * @param {readonly T[]} list - A lista.
 * @returns {T[]} A legalább kétszer szereplők listája.
 */
module.exports.findDuplicates = function findDuplicates (list) {
  const tmp = [ ...list ].sort((a, b) => {
    if (is.string(a) && is.string(b)) {
      return a.localeCompare(b)
    }

    // @ts-expect-error köhögne az a - b miatt.
    return a - b
  })

  const duplicates = tmp.reduce((/** @type {T[]} */ acc, curr, index) => {
    if (curr === tmp[index + 1]) {
      acc.push(curr)
    }

    return acc
  }, [])

  return duplicates
}
