import { Object } from '@common/Object/Object'
import { is } from '@common/is/is'

import type { TTarget } from '../definitions'

/**
 * A nyelvi objectek összemergeléséhez szükséges.
 * @param target - Target.
 * @param source - Kiindulási adat.
 */
export default function mergeDeep (target: TTarget, source?: unknown): TTarget {
  if (is.object(target) && is.object(source)) {
    const src: TUnknownObject = source

    for (const key in src) {
      if (is.object(src[key])) {
        if (!(target as TUnknownObject)[key]) {
          Object.assign(target, { [key]: {} })
        }

        mergeDeep((target as never)[key], src[key])
      }
      else {
        Object.assign(target, { [key]: src[key] })
      }
    }
  }

  return target
}
