const { isRegex } = require('./isRegex')

/**
 * Az `s` stringet alakítja át RegExp-re.
 * @param {string} s - A megadott string.
 * @returns {RegExp | null} A megfelelő RegExp, ha nem parse-olható, akkor undefined.
 */
module.exports.getRegex = function getRegex (s) {
  // Valid RegExp
  if (isRegex(s)) {
    const firstSlashIndex = s.indexOf('/')
    const lastSlashIndex = s.lastIndexOf('/')

    const flags = s.slice(lastSlashIndex + 1)
    const content = s.slice(firstSlashIndex + 1, lastSlashIndex)

    return new RegExp(`${ content }`, flags)
  }

  return null
}
