const { isEqual } = require('./isEqual')

/**
 * Megvizsgálja, hogy az `value` és `other` object megegyezik.
 * @template T
 * @param { T } value  - Az első megadott object.
 * @param { T } other - A másik megadott object.
 * @param { (a: T, b: T) => boolean } [customizer] - Egyezést vizsgáló függvény.
 * @returns { boolean } Megyezeik a két object?
 */
module.exports.isEqualWith = function isEqualWith (value, other, customizer) {
  // Ha meg van adva customizer fn akkor annak megfelelően kell vizsgálni az egyezést
  if (customizer) {
    return customizer(value, other)
  }

  // Nincs megadva customizer függvény, isEqual függvényt elég használni.
  return isEqual(value, other)
}
