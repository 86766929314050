import { is } from '@common/is/is'

/**
 * Event(ek) hozzáadása.
 * @param elOrQuery  - Maga az elem, vagy query ami alapján elkérjük az elemet.
 * @param ev         - Event(ek).
 * @param func       - Callback, eseménynél fut meg.
 * @param useCapture - Azt a fázist jelöli ki, amikor az eseményt kezelni szeretnénk (pl. Bubbling).
 */
export default function on ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  elOrQuery: Element | string,
  ev: string,
  func: EventListenerOrEventListenerObject,
  useCapture = false
): void {
  const events = ev.trim().split(/\s+/)

  const el = is.string(elOrQuery) ? document.querySelector(elOrQuery) : elOrQuery

  events.forEach((event) => {
    el?.addEventListener(event, func, useCapture)
  })
}
