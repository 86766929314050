/* eslint-disable import-x/no-mutable-exports, custom/max-top-level-functions-per-file */

import type { TErrorCallback } from '../definitions'

/** A globális errorhandler. */
let globalErrorHandler: TErrorCallback = () => { // eslint-disable-line no-restricted-syntax
  // noop
}

/**
 * Ezzel a függvénnyel felül tudjuk csapni a globális errorkezelőt.
 * @param handler - A callback, amivel felülverjük a globálist.
 */
function setGlobalErrorHandler (handler: TErrorCallback): void {
  globalErrorHandler = handler
}

export { globalErrorHandler, setGlobalErrorHandler }
