/**
 * Ha minden async és nem async asset betöltődött.
 * Az onReady és az onContentLoaded közötti különbségért lásd a README-t!
 * @param callback - Ez fog megfutni.
 */
export default function onReady (callback: TVoidCallback): void {
  if (document.readyState === 'loading') {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        callback()
      }
    })

    return
  }

  callback()
}
