const { is } = require('../../is/is')
const { sample } = require('./sample')

/**
 * Egy elem véletlenszerű kiválasztása a tömbből, kivéve lehetőséggel.
 * @template T
 * @param {readonly T[]} array - A tömbünk.
 * @param {T | readonly T[]} except - Kivéve.
 * @returns {T} Véletlenszerűen kiválasztott.
 */
function sampleExcept (array, except) {
  const exceptArray = is.array(except) ? except : [ except ]

  const tmp = sample(array)

  /** @type {any} */
  const res = exceptArray.includes(tmp)
    ? sampleExcept(array, exceptArray)
    : tmp

  return res
}

module.exports.sampleExcept = sampleExcept
