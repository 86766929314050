const { RX } = require('../RX/RX')

const { Number } = require('../Number/Number')

/**
 * Véletlenszerű id generálása prefix-szel.
 * @param   {string} [prefix='id-'] - Id elé bekerülő prefix.
 * @returns {string}                Kapott id stringként, prefix-szel..
 */
module.exports.getRandomId = function getRandomId (prefix = 'id-') {
  const chars = Math.random().toString(36).slice(2, 11)

  // Valamiért nem sikerült 9-es hosszúságú ID-t generálni, próbáljuk újra.
  if (chars.length < 9) {
    return getRandomId(prefix)
  }

  return `${ prefix }${ new Date().getUTCMilliseconds() }_${ chars }`
}

/**
 * Véletlenszerű igaz vagy hamis.
 * @param   {number}  [percentage=50] - Hány százalékban dobjon igazat.
 * @returns {boolean}                 Igaz vagy hamis.
 */
module.exports.randomBool = function randomBool (percentage = 50) {
  return Number.random(0, 99) < percentage
}

/**
 * HTML stringek kiszedése.
 * @param {any} el - Az elem, amiből ki akarjuk szedni a html-t.
 */
module.exports.stripHtml = function stripHtml (el) {
  // eslint-disable-next-line unicorn/prefer-dom-node-text-content -- Ez így marad.
  const txt = (el.textContent || (el.innerText || '')).replace(RX.HTML, '')
  el.innerHTML = txt
}

/**
 * Létrehoz egy debounce-olt függvényt, ami annyi millisecundumig vár, amennyit megadtunk.
 * Eltér a lodash, underscore, stb. Debounceoktól, mert nekünk nem kell annyira svájcibicska.
 * @see {@link https://www.freecodecamp.org/news/javascript-debounce-example/}
 * @see {@link https://github.com/lodash/lodash/blob/master/debounce.js}
 * @see {@link https://css-tricks.com/debouncing-throttling-explained-examples/}
 * @template {(...args: any) => any} T
 * @param   {T}      callback - A callback.
 * @param   {number} timeout  - Mennyi ideig várjon két hívás között?
 * @returns {T}               Egy egyszerű, hívható callback.
 */
module.exports.debounce = function debounce (callback, timeout = 0) {
  /** @type {ReturnType<typeof setTimeout>} */
  let timeoutId

  /**
   * A debounceolt callback függvény.
   * @param {...any} args - A lehetséges paraméterek.
   */
  function debounced (...args) {
    /* eslint-disable no-restricted-globals -- Amíg más függvényekkel közös fájlban van, addig kikapcs. */
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      callback(...args)
    }, timeout)
    /* eslint-enable */
  }

  // @ts-expect-error Nem számít.
  return debounced
}
