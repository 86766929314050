import getScrollTop from './getScrollTop'

/**
 * Látható e az elem a képernyőn.
 * @param node - A vizsgált elem.
 */
export default function inViewPort (node: HTMLElement): boolean {
  const rect = node.getBoundingClientRect()

  if (rect.height <= 0) {
    return false
  }

  const viewTop = getScrollTop()
  const viewBtm = viewTop + window.innerHeight
  const nodeTop = rect.top + viewTop
  const nodeBtm = nodeTop + node.offsetHeight

  return (nodeBtm >= viewTop) && (nodeTop <= viewBtm)
}
