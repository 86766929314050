import type { TLanguage } from '../definitions'

/**
 * Fallback lang visszaadása.
 * @param lang - A jelenlegi nyelvünk.
 */
export default function getFallbackLang (lang: TLanguage): TLanguage | null {
  if (lang === 'hu') {
    return null
  }

  if (lang === 'en') {
    return 'hu'
  }

  return 'en'
}
