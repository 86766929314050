// eslint-disable-next-line custom/no-restricted-imports
import type { TStyle } from '@common/jsxRender/definitions'

import styles from './Input.scss'

interface IData {
  readonly [key: string]: string
}

export interface IElement {
  readonly data?: IData
  readonly name?: string
  readonly placeholder?: string
  readonly className?: string
  readonly style?: TStyle
}

export interface IProps extends IElement {
  readonly [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
  readonly value?: string
  readonly type?: 'text' | 'number' | 'hidden' | 'radio' | 'color' | 'checkbox' | 'password'
  readonly min?: number
  readonly max?: number
  readonly disabled?: boolean
  readonly checked?: boolean
  readonly onInput?: (e: Event) => void
  readonly maxlength?: number
}

/**
 * Beviteli mező fölött wrapper factory.
 * @param props - JSX props {@link IProps}.
 */
export default function Input (props: IProps): HTMLInputElement {
  const { className = '', type = 'text', ...rest } = props
  const cname = [ styles.input, className ]

  return (
    <input
      className={ cname }
      type={ type }
      { ...rest }
    />
  )
}
