const { sort }    = require('./sort')
const { reverse } = require('./reverse')

/**
 * @typedef {'asc' | 'desc'} TOrder
 */

/**
 * Rendezi a `array` tömb elemeit `keys` kulcsok alapján.
 * @template {Object} T
 * @template {keyof T} K
 * @param {ReadonlyArray<T>} array - A rendezendő tömb.
 * @param {ReadonlyArray<K>} keys - A rendezés kulcsai.
 * @param {TOrder} order - A rendezés iránya.
 * @returns {Array<T>} A rendezett tömb.
 */
module.exports.sortByKey = function sortByKey (array, keys, order = 'asc') {
  const sorted = sort(array, (a, b) => {
    for (const key of keys) {
      const aValue = a[key]
      const bValue = b[key]

      if (aValue === bValue) {
        // eslint-disable-next-line no-continue
        continue
      }

      return aValue < bValue
        ? -1
        : aValue > bValue
          ? 1
          : 0
    }

    return 0
  })

  return order === 'asc'
    ? sorted
    : reverse(sorted)
}
