/**
 * Megviszgálja, hogy a `searchElement` benne van-e az adott listában.
 * @template {ReadonlyArray<any>} T
 * @param {T} list - A lista, amiben vizsgálódunk.
 * @param {unknown} searchElement - Paraméter, amit vizsgálunk.
 * @param {number} [fromIndex] - Start index, hanyas indextől keressen a tömbben.
 * @returns {searchElement is T[number]} A keresendő paraméter benne van a listában?
 */
module.exports.includes = function includes (list, searchElement, fromIndex) {
  return list.includes(/** @type {T} */ (searchElement), fromIndex)
}
