const { randomUniqNumbers } = require('../utils/randomUniqNumbers')

/**
 * Véletlenszerűen kiválasztott elemek egy tömbből.
 * @template T
 * @param {ReadonlyArray<T>} array         - A tömbbünk.
 * @param {number}           count         - Hány elemet kérünk.
 * @param {ReadonlyArray<T>} except        - Kivéve.
 * @param {boolean}          isConsecutive - Növekvő sorrend.
 * @returns {Array<T>} A kiválasztott elemek.
 */
module.exports.samples = function samples (array, count = 1, except = [], isConsecutive = false) {
  const filteredArray = array.filter((item) => {
    return except.every((exceptItem) => exceptItem !== item)
  })

  const randIndexes = randomUniqNumbers(count, 0, filteredArray.length - 1)

  if (isConsecutive) {
    randIndexes.sort((a, b) => (a - b))
  }

  return randIndexes.map((index) => filteredArray[index])
}
