/* eslint-disable @typescript-eslint/naming-convention -- Itt masszívan a szabályok ellen dolgozunk.:) */

export type TProtocol = 'http:' | 'https:'

/**
 * A ContentType-oknak NE legyen része a charset UTF-8!
 * @see {@link https://stackoverflow.com/questions/23714383/what-are-all-the-possible-values-for-http-content-type-header}
 */
export enum EContentType {
  JSON           = 'application/json',
  HTML           = 'text/html',
  XML            = 'application/xml',
  JPG            = 'image/jpg',
  PNG            = 'image/png',
  Plain          = 'text/plain',
  FormData       = 'multipart/form-data',
  FormUrlEncoded = 'application/x-www-form-urlencoded'
}

/** @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods} */
export type TRequestMethod = (
  'GET' |
  'HEAD' |
  'POST' |
  'PUT' |
  'DELETE' |
  'CONNECT' |
  'OPTIONS' |
  'TRACE' |
  'PATCH'
)

/**
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 * Lehetséges HTTP státuszkódok.
 * Nem lehet const enum, mert az ajax-hoz hozzákötjük, mint object.
 */
export enum EHttpStatusCode {
  Ok                            = 200,
  MovedPermanently              = 301,
  Found                         = 302,
  NotModified                   = 304,
  BadRequest                    = 400,
  Unauthorized                  = 401,
  NotFound                      = 404,
  InternalServerError           = 500,
  ServiceUnavailable            = 503,
  NetworkAuthenticationRequired = 511
}

export type THttpStatus = keyof typeof EHttpStatusCode

type TStatusMessages = {
  readonly [key in THttpStatus]: string
}

export const statusMessage: TStatusMessages = {
  Ok: 'Ok',
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  NotModified: 'Not Modified',
  InternalServerError: 'Internal Server Error',
  ServiceUnavailable: 'Service Unavailable',
  NetworkAuthenticationRequired: 'Network Authentication Required',
  Unauthorized: 'Unauthorized',
  MovedPermanently: 'Moved Permanently',
  Found: 'Changed Temporarily'
}

export interface ICrossResponse<T extends TObject> {
  readonly isSuccess: boolean
  readonly data: T | TAnyObject
}

export interface IFormValue {
  readonly key: string
  readonly value: string
}

export interface IFormBufferFile {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Meh.
  readonly data: any
  readonly fileName: string
}

export interface IFormFile {
  readonly key: string
  readonly filePath: string | null
  readonly data: IFormBufferFile | null
}

export interface IFormOptions {
  readonly host: string
  readonly path: string
  readonly protocol: TProtocol
  readonly port?: number
  readonly fields: readonly IFormValue[]
  readonly files: readonly IFormFile[]
}
