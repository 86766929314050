import globalEvents from '@common/globalEvents/globalEvents'

import onClick from './utils/onClick'

import './ToTop.css'

const VISIBLE_FROM  = 20

interface IProps {
  readonly children?: HTMLElement[]
}

/**
 * ToTop komponens factory-ja.
 * @param props - JSX property-k {@link IProps}.
 */
export default function ToTop (props: IProps): HTMLElement {
  const content = (props?.children?.length ?? 0) > 0 ? props.children : 'FEL'

  const toTop: HTMLElement = (
    <div
      className="sen-to-top"
      onClick={ onClick }
    >
      { content }
    </div>
  )

  let isVisible = false

  /** Scroll esemény figyelése. Itt vizsgálja, hogy látható-e vagy el kell tűntetni magát. */
  function onScroll (): void {
    const { scrollTop } = document.documentElement

    if (scrollTop >= VISIBLE_FROM && !isVisible) {
      toTop.setAttribute('data-visible', '')
      isVisible = true
    }
    else if (scrollTop < VISIBLE_FROM && isVisible) {
      toTop.removeAttribute('data-visible')
      isVisible = false
    }
  }

  globalEvents.onScroll(onScroll)

  onScroll()

  return toTop
}
