interface IMatchMedia {
  addEventListener: (type: string, listener: TVoidCallback) => void
  removeEventListener: (type: string, listener: TVoidCallback) => void
  isMatch: () => boolean
}

/**
 * Létrehoz egy MediaQueryList-et.
 * @param mediaQueryString - A media query string.
 */
export default function create (mediaQueryString: string): IMatchMedia  {
  const mm = window.matchMedia(mediaQueryString)

  /**
   * Normalizált matchMedia például iOS Safari-hoz.
   * Https://stackoverflow.com/questions/62693995/addeventlistener-is-not-a-function-for-matchmedia-in-safari-browser.
   */
  const normalizedMatchMedia = {
    addEventListener: (type: string, listener: TVoidCallback) => {
      if (mm.addEventListener) {
        mm.addEventListener(type, listener)
      }
      else {
        mm.addListener(listener)
      }
    },
    removeEventListener: (type: string, listener: TVoidCallback) => {
      if (mm.removeEventListener) {
        mm.removeEventListener(type, listener)
      }
      else {
        mm.removeListener(listener)
      }
    },
    isMatch: () => {
      return mm.matches
    }
  }

  return normalizedMatchMedia
}
