import { is } from '@common/is/is'

import transLog from './transLog'

/**
 * Az összes behelyettesítése.
 * @param txt      - Ebben kell cserélni.
 * @param replaces - Ezek a behelyettesítendő szövegek.
 */
export default function replaceAll (txt: string, ...replaces: (string | number)[]): string {
  // Nincs mit replacelni.
  if (is.empty(replaces)) {
    return txt
  }

  // A szövegben nincs mit replace-elni
  if (!(/\$\d/).test(txt)) {
    transLog('Replacelni szeretnél, de a szövegedben nincs $ jelölés!')

    return txt
  }

  const replaced = replaces
    .reduce<string>((acc, curr, i) => {
      return acc.replace(
        new RegExp(`\\$${ i + 1 }`, 'gm'),
        `${ curr }`
      )
    }, txt)

  return replaced
}
