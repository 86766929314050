import { is } from '@common/is/is'

/**
 * Bármilyen HTMLElement-nek be lehet állítani vele értéket.
 * @param element - Az elem.
 * @param value   - Az érték.
 */
export default function setValue<T extends HTMLInputElement | HTMLSelectElement | undefined | null> (
  element: T,
  value?: unknown
): T {
  if (!element) {
    return element
  }

  if (
    is.instanceOf(element, HTMLInputElement) &&
    element.type === 'checkbox'
  ) {
    const isChecked = is.boolean(value) ? value : true
    element.toggleAttribute('checked', isChecked)

    return element
  }

  if (
    is.instanceOf(element, HTMLSelectElement)
  ) {
    if (!is.string(value) && !is.number(value)) {
      return element
    }

    element.value = is.number(value) ? `${ value }` : value

    const options = Array.from(element.options) // HTMLOptionsCollection → []
    let hasSelected = false

    options.forEach((opt) => {
      if (opt.value == value) { // eslint-disable-line eqeqeq -- Nem kell típusbiztos vizsgálat.
        hasSelected = true
        opt.setAttribute('selected', '')

        return
      }

      opt.removeAttribute('selected')
    })

    if (!hasSelected && !is.empty(options)) {
      element.selectedIndex = 0
    }

    return element
  }

  if (is.primitive(value)) {
    element.value = `${ value }`
  }

  return element
}
