/* eslint-disable no-restricted-globals, n/prefer-global/url -- Itt nem akarunk require-t használni, mert lehet browserben vagyunk. */

const { is } = require('../../is/is')

/**
 * A megadott paraméter parsolható-e URL-ként?
 * @param {unknown} url - Az ismeretlen paraméter.
 * @returns {boolean} Igaz, ha parsolható.
 */
module.exports.isParsable = function isParsable (url) {
  if (!is.string(url)) {
    return false
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/URL/canParse_static
  if (is.property(URL, 'canParse')) {
    return URL.canParse(url)
  }

  try {
    // @ts-expect-error -- Never.
    const _ = new URL(url)

    return true
  }
  catch {
    return false
  }
}
