import { is } from '@common/is/is'

import calculateScrollBarWidth from './calculateScrollbarWidth'

let scrollBarWidth: number | null = null

/** ScrollBar szélessége. */
export default function getScrollbarWidth (): number {
  scrollBarWidth = is.null(scrollBarWidth) ? calculateScrollBarWidth() : scrollBarWidth

  return scrollBarWidth
}
