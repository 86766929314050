import { EHttpStatusCode } from '@common/http/definitions'

import type { IParams } from './xhrMaker'
import xhrMaker from './xhrMaker'

import { setGlobalErrorHandler } from './globalErrorHandler'
import { setGlobalSuccessHandler } from './globalSuccessHandler'
import getAttributeValuePairsFromElement from './getAttributeValuePairsFromElement'

/**
 * Egy ajax request létrehozása.
 * @param url    - Az url, ahova lőjjük a request-et.
 * @param params - A paraméterek, amik alapján beállítjuk a request-et.
 */
function ajax (url: string, params: IParams): void {
  xhrMaker(url, params)
}

ajax.setGlobalErrorHandler   = setGlobalErrorHandler
ajax.setGlobalSuccessHandler = setGlobalSuccessHandler

ajax.statusCode = EHttpStatusCode

ajax.getAttributeValuePairsFromElement = getAttributeValuePairsFromElement

export default ajax
