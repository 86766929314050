/**
 * Egy elemtől querySelectorral elkéri a query-ben definiált elemet.
 * @param el    - Az elem, amin a query-t ellőjjük.
 * @param query - A querystring.
 */
export default function query<T extends Element, K extends Element> (el: T, query: string): K | null {
  const res = el.querySelector<K>(query)

  return res
}
