/**
 * Font folder elkérése, a font neve alapján.
 * @param fontName - Font neve.
 */
export default function getFontFolderName (fontName: string): string {
  if (fontName.includes('+')) {
    const newFontFolderName = fontName.replace('+', '')

    return getFontFolderName(newFontFolderName)
  }

  return fontName
}
