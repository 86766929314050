import { baseGlobals } from '@root/typescript/baseGlobals'

/**
 * Logolás egyszerűsítése.
 * @param params - Kilogolandó információ.
 */
export default function log (...params: unknown[]): void {
  if (baseGlobals.isDev || baseGlobals.isCordova) {
    console.log(...params) /* eslint-disable-line no-console */
  }
}
