const { range } = require('./range')

/**
 * Inclusive range.
 * A különbség annyi a simához képest, hogy a végpont is bekerül a listába.
 *
 * @example
 * range(0, 5) → [ 0, 1, 2, 3, 4 ]
 * irange(0, 5) → [ 0, 1, 2, 3, 4, 5 ]
 *
 * @param {number} start - Kezdő érték.
 * @param {number} end - Befejező érték.
 * @param {number} [step=1] - Lépés mértéke.
 *
 * @returns {number[]} A generált számok.
 */
module.exports.irange = function irange (start, end, step = 1) {
  return range(start, end + step, step)
}
