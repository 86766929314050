const { URI } = require('../../URI/URI')
const { is } = require('../../is/is')

/**
 * URL összerakása és kicserélése a paraméterek alapján.
 * @param {string} route - Az eredeti route.
 * @param {(string | number)[]} params - A behelyettesítendő paraméterek.
 * @returns {string} Az URL.
 */
module.exports.createRoute = function createRoute (route, params) {
  /**
   * A route szétdarabolva.
   * ```ts
   * /api/codeService/codeActivities/:param1/:param2
   * [ 'api', 'codeService', 'codeActivites', ':param1', ':param2' ]
   * ```
   */
  const splitted = route.split('/')

  /** @type { { parts: string[], values: (string | number)[] }} */
  const init = {
    parts: [],
    values: params.map((part) => URI.encodeComponent(part))
  }

  const { parts } = splitted.reduce((acc, curr) => {
    if (!curr.startsWith(':')) {
      acc.parts.push(curr)

      return acc
    }

    if (is.empty(acc.values)) {
      return acc
    }

    const [ firstEl, ...rest ] = acc.values

    acc.parts.push(`${ firstEl }`)
    acc.values = rest

    return acc
  }, init)

  const res = parts.join('/')

  return res
}
