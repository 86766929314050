const { is }     = require('../../is/is')
const { Object } = require('../../Object/Object')

const { sort } = require('./sort')

/**
 * Visszaadja az összes olyan elemet amely az összes `arr` elemben benne van.
 * @template T
 * @param { Array<Array<T>> } arr - A megadott tömbök.
 * @returns { ReadonlyArray<T> } A metszet.
 */
module.exports.getIntersection = function getIntersection (...arr) {
  const sortedArr = sort(arr, (a, b) => b.length - a.length)

  const [ firstArray = [], ...rest ] = sortedArr

  return firstArray.filter((firstArrayElem) => {
    const comparison = is.object(firstArrayElem)
      ? undefined
      : (/** @type { T }*/a, /** @type { T }*/ b) => a === b

    return rest.every((restArray) => (
      restArray.some((restArrayElem) => Object.isEqualWith(firstArrayElem, restArrayElem, comparison))
    ))
  })
}
