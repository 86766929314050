/**
 * Összeadja a felsorolásban részt vevő számokat, majd az eredményt visszaadja.
 * @param {readonly number[]} args - A számok.
 * @returns {number} Az eredmény.
 */
module.exports.sum = function sum (...args) {
  return args.reduce((acc, curr) => {
    const res = acc + curr

    return res
  }, 0)
}
