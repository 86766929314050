/**
 * Beállítja a corsOrigin-t az XHR-nek.
 * @param xhr        - A példányosított XHR objektum.
 * @param corsOrigin - A cors.
 */
export default function setCorsOrigin (xhr: XMLHttpRequest, corsOrigin: string): void {
  // eslint-disable-next-line no-console
  console.log('[AJAX] corsOrigin:', corsOrigin)

  xhr.setRequestHeader('Access-Control-Allow-Origin', '*') // TODO: ajjajj, ez itt wildcard, viszont ha átírjuk, mit törünk el? :D
  xhr.setRequestHeader('Access-Control-Request-Method', 'POST')
  xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
  xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT')

  const values: readonly string[] = [
    'Access-Control-Allow-Headers',
    'Origin',
    'Accept',
    'X-Requested-With',
    'privatekey',
    'Content-Type',
    'Access-Control-Request-Method',
    'Access-Control-Request-Headers',
    'Access-Control-Allow-Credentials'
  ]

  xhr.setRequestHeader(
    'Access-Control-Allow-Headers',
    values.join(', ')
  )

  xhr.setRequestHeader('Access-Control-Max-Age', '86400')
}
