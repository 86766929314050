/**
 * Elem beszúrása a parent bizonyos „gyereke” elé.
 * @param newNode       - Új elemünk.
 * @param referenceNode - Elem, ehhez viszonyítunk.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore}
 */
export default function before (newNode: Node | null, referenceNode: Node | null): void {
  if (!newNode || !referenceNode) {
    return
  }

  const parent = referenceNode.parentNode

  if (parent) {
    // eslint-disable-next-line no-restricted-properties -- Az egyetlen hely, ahol engedjük.
    parent.insertBefore(newNode, referenceNode)
  }
}
