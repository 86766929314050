// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
export const enum EXhrReadyState {
  Unsent          = 0,
  Opened          = 1,
  HeadersReceived = 2,
  Loading         = 3,
  Done            = 4
}

export const IS_ASYNC = true
export const RESPONSE_BLOB = 'blob'
