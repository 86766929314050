import { JSON } from '@common/JSON/JSON'
import { is } from '@common/is/is'

import getAttributeValuePairsFromElement from './getAttributeValuePairsFromElement'

type TCommon = null | FormData | string

export type TPostData = TCommon | undefined | HTMLElement | TAnyObject | number | boolean

type TResultObject = {
  readonly [key: string]: number | string
}

export type TResult = TCommon | TResultObject

/**
 * Visszakapjuk a POST requestben felküldendő adatot.
 * @param data - Az adat, amit küldenénk.
 */
export default function getPostData (data?: TPostData): TResult {
  // Nincs semmi.
  if (is.nullable(data)) {
    return null
  }

  // FormData megy vissza úgy ahogy van.
  if (is.instanceOf(data, FormData)) {
    return data
  }

  // Sima HTMLElement, abból összegyűjtjük a name attribútummal rendelkező elemeket.
  if (is.instanceOf(data, HTMLElement)) {
    const result = getAttributeValuePairsFromElement(data)

    if (is.null(result)) {
      return null
    }

    return JSON.numericStringify(result)
  }
  else if (is.object(data)) {
    return JSON.numericStringify(data)
  }

  // Ekkor number vagy boolean.
  return `${ data }`
}
