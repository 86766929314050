import type { TDisconnect } from '../definitions'

/**
 * Blur esetén meghívódik a feliratkozott callback.
 * Leírásért lásd README!
 * @param callback - A callback, ami meg fog hívódni.
 */
export default function onBlur (callback: TVoidCallback): TDisconnect {
  window.addEventListener('blur', callback)

  return {
    disconnect: () => {
      window.removeEventListener('blur', callback)
    }
  }
}
