/**
 * Elemek listájának elkérése querystring alapján.
 * @param el    - Az elem, amin a query-t ellőjjük.
 * @param query - A query.
 */
export default function queryAll<T extends Element, K extends Element> (el: T, query: string): K[] {
  const list = Array.from(el.querySelectorAll<K>(query))

  return list
}
