import before from './before'

/**
 * Létrehoz egy script elemet, ha még nem található a DOM-ban és berakja a head-be.
 * @param src - A source url.
 */
export default function createScript (src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const isAlreadyCreated = Array
      .from(document.querySelectorAll<HTMLScriptElement>('script[src]'))
      .find((script) => script.src.split('?')?.[0]?.includes(src))

    if (isAlreadyCreated) {
      resolve()

      return
    }

    const script = document.createElement('script')
    script.async = true
    script.src   = `${ src }?t=${ Date.now() }` // no-cache

    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', (error) => {
      reject(error)
    })

    const [ s ] = Array.from(document.scripts)

    if (s) {
      before(script, s)
    }
  })
}
