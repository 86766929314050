import DOM from '@common/DOM/DOM'

import {    TRANSLATE_LOCALE_ADDED_EVENT } from '../definitions'
import type { TLanguage, ITranslation, TTranslations } from '../definitions'

import mergeDeep from './mergeDeep'

// Összes nyelvesítést tároló object,
// a kulcs az adott nyelv, az értéke a nyelvi object.
const localeData: TTranslations = {}

const localeHandler = {
 /**
  * Újabb adag szöveg hozzáadása.
  * @param lang - Milyen nyelvhez adjuk hozzá.
  * @param json - Hozzáadandó json.
  */
  addLocale: (lang: TLanguage, json: ITranslation): void => {
    const newTranslations = mergeDeep(json) as ITranslation // HACK: itt a mergeDeep TTarget-et ad vissza, amit kasztolunk :/

    // Ha már van az adott nyelvhez fordítás, akkor egészítse ki azt
    // Ez egy meglévő problémát old meg, ha van activator is és SNI app kell mellé, akkor a kettőből egyet gyúr
    localeData[lang] = localeData[lang] ? { ...localeData[lang], ...newTranslations } : newTranslations

    // Szólunk, hogy új nyelv lett hozzáadva.
    DOM.dispatch(document, TRANSLATE_LOCALE_ADDED_EVENT, false, lang)
  },
  /**
   * Egy adott nyelvhez visszaadja a teljes nyelvi adatot.
   * @param lang - A nyelv.
   */
  getLocale: (lang: TLanguage): ITranslation | undefined => {
    return localeData[lang]
  },
  /** Visszaadja az egész nyelvi adatot. */
  getAllLocales: (): TTranslations => {
    return localeData
  }
}

export default localeHandler
