const { randomUniqNumbers } = require('./utils/randomUniqNumbers')
const { getIntersection }   = require('./utils/getIntersection')
const { findDuplicates }    = require('./utils/findDuplicates')
const { getItemsExcept }    = require('./utils/getItemsExcept')
const { removeByIndex }     = require('./utils/removeByIndex')
const { getLastIndex }      = require('./utils/getLastIndex')
const { sampleExcept }      = require('./utils/sampleExcept')
const { flattenDeep }       = require('./utils/flattenDeep')
const { sequential }        = require('./utils/sequential')
const { sortByKey }         = require('./utils/sortByKey')
const { sequence }          = require('./utils/sequence')
const { includes }          = require('./utils/includes')
const { getLast }           = require('./utils/getLast')
const { flatten }           = require('./utils/flatten')
const { shuffle }           = require('./utils/shuffle')
const { reverse }           = require('./utils/reverse')
const { samples }           = require('./utils/samples')
const { sample }            = require('./utils/sample')
const { uniqBy }            = require('./utils/uniqBy')
const { irange }            = require('./utils/irange')
const { times }             = require('./utils/times')
const { chunk }             = require('./utils/chunk')
const { range }             = require('./utils/range')
const { from }              = require('./utils/from')
const { sort }              = require('./utils/sort')
const { uniq }              = require('./utils/uniq')
const { sum }               = require('./utils/sum')

/** A Goku stdlib részének tömböket kezelő könyvtára. */
const gokuArray = {
  randomUniqNumbers,
  getIntersection,
  findDuplicates,
  getItemsExcept,
  removeByIndex,
  getLastIndex,
  sampleExcept,
  flattenDeep,
  sequential,
  sortByKey,
  sequence,
  includes,
  getLast,
  flatten,
  shuffle,
  reverse,
  samples,
  sample,
  uniqBy,
  irange,
  times,
  range,
  chunk,
  from,
  uniq,
  sort,
  sum
}

module.exports.Array = gokuArray
