import type { TDisconnect } from '../definitions'

/**
 * Focus esetén meghívódik a feliratkozott callback.
 * Leírásért lásd README!
 * @param callback - A callback, ami meg fog hívódni.
 */
export default function onFocus (callback: TVoidCallback): TDisconnect {
  window.addEventListener('focus', callback)

  return {
    disconnect: () => {
      window.removeEventListener('focus', callback)
    }
  }
}
