import { JSON } from '@common/JSON/JSON'
import { is } from '@common/is/is'

import { EContentType, EHttpStatusCode } from '@common/http/definitions'

import type { TErrorCallback, TSuccessCallback } from '../definitions'
import { RESPONSE_BLOB, EXhrReadyState } from '../consts'

import { globalErrorHandler } from './globalErrorHandler'

import { globalSuccessHandler } from './globalSuccessHandler'

/**
 * Az AJAX válaszának feldolgozását szolgáló függvény.
 * @param xhr             - A példányosított XHR object.
 * @param errorCallback   - ErrorCallback, ha hiba történne a feldolgozáskor.
 * @param successCallback - SuccessCallback, ha minden jól ment.
 */
export default function makeOnReadyStateChange (
  xhr: XMLHttpRequest,
  errorCallback?: TErrorCallback,
  successCallback?: TSuccessCallback
): TVoidCallback {
  return function onReadyStateChange (): void {
    if (xhr.readyState !== EXhrReadyState.Done) {
      return
    }

    /** Azért lowercaseljük, mert lehetnek esetek, hogy nem a valid, lowercaselt headert adják meg. */
    const ct = (xhr?.getResponseHeader('Content-Type') ?? '').toLowerCase()

    /** JSON-t küldtek? */
    const isJson = ct.startsWith(EContentType.JSON) // Nem 1:1 egyezést kell vizsgálni, mert az application/json mellé odarakhatjuk a ;charset=utf-8 -at is.

    let jsonOrResponseText: any // eslint-disable-line @typescript-eslint/no-explicit-any

    // Itt ne használd a JSON "biztonságos" függvényeit, hogy ki tudjuk logolni, ha malformed a json.
    try {
      jsonOrResponseText = isJson ? JSON.strictNumericParse(xhr.responseText) : xhr.responseText
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('[AJAX] hiba: JSON.parse', xhr.responseText)
    }

    if (xhr.status !== EHttpStatusCode.Ok) {
      const responseBody = xhr.response ?? (
        xhr.status === EHttpStatusCode.NotFound
          ? '[404]'
          : '[AJAX] Ismeretlen hiba!'
      )

      const tmp = isJson
        ? jsonOrResponseText
        : xhr.responseType === RESPONSE_BLOB
          ? responseBody
          : xhr.responseText || responseBody

      if (errorCallback) {
        const wasTreated = errorCallback(tmp, xhr)

        // Ha nem kezelte az errort, akkor megy a globálisnak.
        if (is.boolean(wasTreated) && !wasTreated) {
          globalErrorHandler(tmp, xhr)
        }
      }
      else {
        globalErrorHandler(tmp, xhr)
      }

      return
    }

    if (successCallback) {
      successCallback(jsonOrResponseText, xhr)
    }
    else {
      globalSuccessHandler(jsonOrResponseText, xhr)
    }
  }
}
