const { is }     = require('../../is/is')

const { getObjectKeysRecursive } = require('./getObjectKeysRecursive')
const { getObjectValuesByKey }   = require('./getObjectValuesByKey')

/**
 * Megyegezik-e a két paraméterként kapott elem? (típus, és értékek is).
 * @template T
 * @param { T } value - Az első elem.
 * @param { T } other - A második elem, amihez hasonlítunk.
 * @returns {boolean} Megegyezik a két elem?
 */
module.exports.isEqual = function isEqual (value, other) {
  if (is.null(value) || is.null(other)) {
    return value === other
  }

  if (is.undefined(value) || is.undefined(other)) {
    return value === other
  }

  if (is.array(value) && is.array(other)) {
    if (value.length === other.length) {
      return value.every((actualValue, i) => isEqual(actualValue, other[i]))
    }

    return false
  }

  if (is.object(value) && is.object(other)) {
    return getObjectKeysRecursive(value).every((key) => {
      return getObjectValuesByKey(value, key) === getObjectValuesByKey(other, key)
    })
  }

  // Egyszerű típusok ellenőrzéséhez
  return value === other
}
