import { baseGlobals } from '@root/typescript/baseGlobals'

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Sajnos itt ez kell, mert rengeteg helyen használjuk és nem csak Eventhez, de errorhoz is.
type TCallback = (e?: any) => void

/**
 * Eseménykezelő rákötése egy elemre. Csak egyszer fut meg.
 * @param el         - Maga az elem.
 * @param eventName  - Event.
 * @param callback   - Callback, eseménynél fut meg.
 * @param useCapture - Azt a fázist jelöli ki, amikor az eseményt kezelni szeretnénk (pl. Bubbling).
 */
export default function once ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  el: HTMLElement | Document,
  eventName: string,
  callback: TCallback,
  useCapture = false
): void {
  /**
   * Eseményfigyelő eltávolítása.
   * @param e - Event.
   */
  function one (e?: Event): void {
    el.removeEventListener(eventName, one, useCapture)
    callback(e)
  }

  el.addEventListener(eventName, one, useCapture)

  if (
    baseGlobals.isTest &&
    (
      eventName === 'transitionend' ||
      eventName === 'animationend'
    )
  ) {
    one()
  }
}
