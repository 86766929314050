const { is }     = require('../../is/is')
const { Object } = require('../../Object/Object')

/**
 * @template T
 * @typedef {ReadonlyArray<T> | Record<string | number, T>} TCollection
 */

/**
 * Tömb keverése.
 * A függvény az eredeti paramétert nem módosítja, hanem egy új tömbbel tér vissza.
 * @template T
 * @param {TCollection<T>} collection - A megadott érték (tömb vagy object).
 * @returns {Array<T>} A megkevert értékek.
 */
module.exports.shuffle = function shuffle (collection) {
  // A collection értékeit adja vissza, ha tömb, akkor az értékeit, ha object, akkor a kulcsokhoz tartozó értékeket
  const collectionValues = [
    ...(
      is.array(collection)
        ? collection
        : Object.getValues(collection)
    )
  ]

  let currIndex = collectionValues.length

  while (currIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currIndex)
    currIndex = currIndex - 1

    const tmp = collectionValues[currIndex]
    collectionValues[currIndex] = collectionValues[randomIndex]
    collectionValues[randomIndex] = tmp
  }

  return collectionValues
}
