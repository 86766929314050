/* eslint-disable no-restricted-globals -- Object. */

const { getObjectKeysRecursive } = require('./utils/getObjectKeysRecursive')
const { getObjectValuesByKey }   = require('./utils/getObjectValuesByKey')
const { setObjectValues }        = require('./utils/setObjectValues')
const { getKeysByValue }         = require('./utils/getKeysByValue')
const { isEqualWith }            = require('./utils/isEqualWith')
const { getEntries }             = require('./utils/getEntries')
const { mergeWith }              = require('./utils/mergeWith')
const { getValues }              = require('./utils/getValues')
const { getKeys }                = require('./utils/getKeys')
const { isEqual }                = require('./utils/isEqual')
const { invert }                 = require('./utils/invert')

/** Ez egy HACK megoldás, mert ha objectként definiáljuk, akkor WP problémát kapunk, hogy ezt akarja natívként használni. */
const GokuObject = class GokuObject {}

GokuObject.getObjectKeysRecursive = getObjectKeysRecursive
GokuObject.getObjectValuesByKey   = getObjectValuesByKey
GokuObject.setObjectValues        = setObjectValues
GokuObject.getKeysByValue         = getKeysByValue
GokuObject.isEqualWith            = isEqualWith
GokuObject.getEntries             = getEntries
GokuObject.mergeWith              = mergeWith
GokuObject.getValues              = getValues
GokuObject.getKeys                = getKeys
GokuObject.isEqual                = isEqual
GokuObject.invert                 = invert

// Natív aliasok.
GokuObject.defineProperties = Object.defineProperties
GokuObject.defineProperty   = Object.defineProperty
GokuObject.assign           = Object.assign

module.exports.Object = GokuObject
