/** Legacy okokból a két wantednek a FullHD-hoz kell igazodnia. */
const WANTED_W = 1920
const WANTED_H = 1080

const PRECISION = 4

/**
 * Scale kiszámítása.
 * Ha default paraméterekkel használjuk, akkor érdemes változóba kimenteni az eredményt,
 * mert a window.innerWidth és window.innerHeight reflow-t eredményez!
 * @see {@link https://gist.github.com/paulirish/5d52fb081b3570c81e3a}
 * @param wW      - A window szélessége.
 * @param wH      - A window magassága.
 * @param wantedW - Cél szélesség.
 * @param wantedH - Cél magasság.
 */
export default function getScaleRatio ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  wW      = window.innerWidth,
  wH      = window.innerHeight,
  wantedW = WANTED_W,
  wantedH = WANTED_H
): string {
  const ratioW = wW / wantedW
  const ratioH = wH / wantedH
  const ratio  = Math.min(ratioW, ratioH)

  return ratio.toFixed(PRECISION)
}
