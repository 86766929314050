import { baseGlobals } from '@root/typescript/baseGlobals'
import { is } from '@common/is/is'

import languageHandler from './languageHandler'
import replaceAll      from './replaceAll'
import getTrans        from './getTrans'

/**
 * Az id alapján (pl.: my.nested.text.id) visszakeressük az objectből a szöveget.
 * @param id       - Ami alapján keresünk.
 * @param replaces - Cserélendő részek.
 */
export default function getTransById (id: string, ...replaces: (string | number)[]): string {
  const res = getTrans(id, languageHandler.getLang())

  if (is.null(res)) {
    if (baseGlobals.isDev) {
      return id
    }

    return ''
  }

  return replaceAll(res, ...replaces)
}
