import { is } from '@common/is/is'

/**
 * Régi elemet az új elemre cseréli.
 * A natív replaceChild-hoz képest fordított a paramétersorrend, ott az újat kell előbb megadni és a régit utána.
 * @param oldNode - Kicserélendő elem.
 * @param newNode - Az új elem.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/replaceChild}
 */
export default function replace (oldNode: Node, newNode: Node): void {
  // Ha a modern megoldás elérhető, azt használjuk.
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/replaceWith
  if (is.property(oldNode, 'replaceWith') && is.function(oldNode.replaceWith)) {
    oldNode.replaceWith(newNode)

    return
  }

  if (oldNode.parentNode) {
    oldNode.parentNode.replaceChild(newNode, oldNode)
  }
}
