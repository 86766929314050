import { is } from '@common/is/is'

/**
 * Elem eltávolítása.
 * A függvénynek null és undefined értéket is át lehet adni, ekkor nem történik semmi.
 * @param el - Eltávolítandó element.
 */
export default function remove<T extends Node> (el: T | null | undefined): void {
  if (!el) {
    return
  }

  // Van natív remove metódusa az elemnek.
  if (is.property(el, 'remove') && is.function(el.remove)) {
    el.remove()

    return
  }

  /* eslint-disable no-restricted-properties -- Oldschool módszer. */
  if (el?.parentNode) {
    el.parentNode.removeChild(el)
  }
  /* eslint-enable no-restricted-properties */
}
