import { Number } from '@common/Number/Number'

import ContentScale from '@common/ContentScale/ContentScale'

/**
 * MouseMovement elkérése.
 * @param e - MouseEvent.
 */
export default function getMouseMovement (e: MouseEvent): { x: number, y: number } {
  const event = e as unknown as {
    movementY: number
    movementX: number
    mozMovementY: number
    mozMovementX: number
    webkitMovementY: number
    webkitMovementX: number
  }

  const movementX = event.movementX ||
    event.mozMovementX ||
    event.webkitMovementX ||
    0

  const movementY = event.movementY ||
    event.mozMovementY ||
    event.webkitMovementY ||
    0

  let x = movementX
  let y = movementY

  const root = document.body.querySelector('#root')

  // Ha van root id-jű elemünk, akkor jóeséllyel használunk ContentScale-t.
  if (root) {
    const ratioNum = 1 / Number.parse(ContentScale.getScaleRatio())

    x = Math.floor(movementX * ratioNum)
    y = Math.floor(movementY * ratioNum)
  }

  return { x, y }
}
