import { baseGlobals } from '@root/typescript/baseGlobals'

/**
 * Nem kell, hogy tesztelés közben telelogolja a console-t.
 * @param props - Bármi kilogolandó.
 */
export default function transLog (...props: unknown[]): void {
  if (!baseGlobals.isTest && baseGlobals.isDev) {
    // eslint-disable-next-line no-console
    console.log('[i18n]', ...props)
  }
}
