import { baseGlobals } from '@root/typescript/baseGlobals'
import { is } from '@common/is/is'
import { Object } from '@common/Object/Object'

import type { IProps, TChildren, IDangerous, TReturnHTMLElementType } from '../definitions'

import Fragment from './Fragment'

// Ezek egyel kevesebbek a 0-ás indexelés miatt
const THIRD_CHAR = 2
const FIFTH_CHAR = 4

type TJsxAttribute = 'htmlFor' | 'spellCheck' | 'srcSet'

const JSX_ATTRIBUTES_PAIRS: TReadonlyRecord<TJsxAttribute, string> = {
  htmlFor: 'for',
  spellCheck: 'spellcheck',
  srcSet: 'srcset'
}

const JSX_ATTRIBUTES = Object.getKeys(JSX_ATTRIBUTES_PAIRS)

/**
 * Generálófüggvény.
 * @param tagName  - HTML tag név.
 * @param props    - JSX property.
 * @param children - Lehetséges gyermekek.
 */
export default function createElements<T extends string> (
  tagName: T,
  props?: IProps,
  children?: TChildren
): TReturnHTMLElementType<T> {
  const element = document.createElement(tagName)

  if (is.array(children) && !is.empty(children)) {
    // eslint-disable-next-line no-restricted-syntax -- Meh.
    const fragment = Fragment({ children })

    element.appendChild(fragment)
  }

  if (!props) {
    return element as TReturnHTMLElementType<T>
  }

  Object.getEntries(props).forEach(([ key, value ]) => {
    // Nem string paramétert kaptunk, egyből mehet a return
    if (!is.string(key)) {
      return
    }

    // Style beállítása
    if (key === 'style') {
      // Object van megadva
      if (is.object(value)) {
        Object.assign(element.style, value)
      }
      else if (baseGlobals.isDev) {
        // eslint-disable-next-line no-console
        console.error('[JSX-RENDER] style csak object lehet! Kapott adatok:', element, key, value)
      }
    }

    // ClassName beállítás
    else if (key === 'className') {
      // Tömbben legalább egy elem meg van adva, vagy valid string van megadva
      if (
        (is.array(value) || is.string(value)) &&
        !is.empty(value)
      ) {
        // <div className={ [ styles.a, styles.b ] } /> --→ <div class="a b">
        // <div className={ [ a > 2 ? styles.a : styles.b, styles.c ] } /> --→ <div class="b c">
        const classList = is.array(value)
          ? value.filter((act) => is.string(act) && !is.empty(act)).join(' ')
          : value

        element.setAttribute('class', classList)
      }
    }

    // Data object attribútumok lekezelése
    // @ts-expect-error - Object validálás...
    else if (key === 'data' && is.object<TAnyObject>(value)) {
      Object.getEntries(value).forEach(([ dataKey, dataValue ]) => {
        if (is.string(dataKey) && !is.empty(dataKey) && is.primitive(dataValue)) {
          element.setAttribute(`data-${ dataKey }`, `${ dataValue }`)
        }
      })
    }

    // DangerouslySetInnerHTML lekezelés
    else if (key === 'dangerouslySetInnerHTML') {
      if (is.object(value) && '__html' in value) {
        element.innerHTML = (value as unknown as IDangerous).__html
      }
    }

    // on Eventek lekezelése
    else if ((/^on[A-Z].+$/m).test(key)) {
      // Function van megadva
      if (is.function(value)) {
        const event = key.slice(THIRD_CHAR).toLowerCase()

        element.addEventListener(event, value as EventListenerOrEventListenerObject)
      }
    }

    // once Eventek lekezelése
    else if ((/^once[A-Z].+$/m).test(key)) {
      // Function van megadva
      if (is.function(value)) {
        const event = key.slice(FIFTH_CHAR).toLowerCase()

        /**
         * EventListener hozzáadása. Egyszeri futás biztosítása.
         * @param e - Maga az event.
         */
        const callback: TEventCallback = ( // eslint-disable-line no-restricted-syntax -- Ezt haggyuk meg így!
          e: Event
        ) => {
          element.removeEventListener(event, callback)

          value(e)
        }

        element.addEventListener(event, callback)
      }
    }

    // Át kell alakítani az attribútumot?
    else if (is.includes(JSX_ATTRIBUTES, key) && is.primitive(value)) {
      const prop = JSX_ATTRIBUTES_PAIRS[key]

      element.setAttribute(prop, `${ value }`)
    }

    else if (key !== 'children'/** && is.primitive(value) .*/) {
      // minden mást sima attribútumként kezelünk
      element.setAttribute(key, `${ value as number | string | boolean }`)
    }
  })

  return element as TReturnHTMLElementType<T>
}
