const { Number } = require('../../Number/Number')

/**
 * Visszaad egy véletlenszerű elemet egy tömbből.
 * @template T
 * @param {readonly T[]} arr - A tömb.
 * @returns {T} A kiválasztott elem.
 */
function sample (arr) {
  const index = Number.random(0, arr.length - 1)

  const item = /** @type {T} Ez itt egy castolás, így hagyd! */ (arr[index])

  return item
}

module.exports.sample = sample
