import before from './before'

/**
 * Elem beszúrása a parent bizonyos gyereke után.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/after}
 * @param newNode       - Új elem.
 * @param referenceNode - Elem, ehhez viszonyítunk.
 */
export default function after (newNode: Node, referenceNode: Node): void {
  if (referenceNode.nextSibling) {
    before(newNode, referenceNode.nextSibling)
  }
  else {
    referenceNode.parentNode?.appendChild(newNode)
  }
}
