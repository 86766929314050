import { is } from '@common/is/is'

import { Number } from '@common/Number/Number'

import ContentScale from '@common/ContentScale/ContentScale'

/**
 * Kurzor pontos pozíciójának elkérése.
 * @param e          - MouseEvent / TouchEvent.
 * @param isTouchEnd - TouchEnd?
 */
export default function getMouseOrTouchPosition<T extends MouseEvent | TouchEvent> (
  e: T,
  isTouchEnd = false
): { x: number, y: number } {
  const isMouseEvent = is.instanceOf(e, MouseEvent)

  const clientX = isMouseEvent ? e.clientX : isTouchEnd ? e.changedTouches[0]?.clientX ?? 0 : e.touches[0]?.clientX ?? 0
  const clientY = isMouseEvent ? e.clientY : isTouchEnd ? e.changedTouches[0]?.clientY ?? 0 : e.touches[0]?.clientY ?? 0

  let x = clientX
  let y = clientY

  const root = document.body.querySelector('#root')

  // Ha van root id-jű elemünk, akkor jóeséllyel használunk ContentScale-t.
  if (root) {
    const rootEl   = root as HTMLElement
    const ratioNum = 1 / Number.parse(ContentScale.getScaleRatio())

    x = Math.floor((clientX - rootEl.offsetLeft) * ratioNum)
    y = Math.floor((clientY - rootEl.offsetTop) * ratioNum)
  }

  return { x, y }
}
