/**
 * @template T
 * @template K
 * @typedef {(el: K, index: number) => Promise<T>} TAsyncCallback
 */

/**
 * PromiseAll-szerű, de szekvenciális.
 * @template T
 * @template K
 * @param {ReadonlyArray<K>} list - Az eredeti végigiterálandó tömb.
 * @param {TAsyncCallback<T, K>} func - Meghívandó függvények.
 * @returns {Promise<T[]>} A generált válaszok tömbje.
 */
async function sequential (list, func) {
  /** @type {T[]} */
  const returnArr = []

  let index = 0

  for (const el of list) {
    const res = await func(el, index)

    returnArr.push(res)

    index++
  }

  return returnArr
}

module.exports.sequential = sequential
