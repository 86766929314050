import { Array } from '@common/Array/Array'
import DOM from '@common/DOM/DOM'

import type { TLanguage, TLanguages } from '../definitions'

import { TRANSLATE_CHANGED_EVENT } from '../definitions'

/** Az összes lehetséges nyelv. */
let availableLanguages: TLanguages = []

/** Az aktuális nyelv. */
let actLang: TLanguage = 'hu'

const languageHandler = {
  init: (languages: TLanguages): void => {
    [ actLang ] = languages
    availableLanguages = languages
  },
  /**
   * Nyelv beállítása.
   * @param lang - Beállítandó nyelv.
   */
  setLang: (lang: string | null | undefined): void => {
    // Az aktuális nyelvet csak akkor állítsuk be, ha valóban van ilyen.
    actLang = Array.includes(availableLanguages, lang)
      // Rendben, van ilyen nyelv a lehetőségek között.
      ? lang
      // Valamiért olyanra próbáltunk állni, ami nem elérhető, ekkor az első, preferált nyelvet válasszuk.
      // Ilyen akkor történhet, ha pl.: egy Ügyesednit lebuildeltünk 3 nyelven (magyar, angol, ukrán) és
      // valaki angolul játszott legutóbb, de a főnökség letiltatta a nyelvválasztást, tehát minden appot
      // újrabuildeltünk kizárólag magyarul.
      // Ekkor az utoljára választott angol nyelv helyett a magyarra áll vissza.
      : availableLanguages[0]

    DOM.dispatch(document, TRANSLATE_CHANGED_EVENT, false, actLang)
  },
  /** Beállított nyelv elkérése. */
  getLang: (): TLanguage => {
    return actLang
  }
}

export default languageHandler
