import isInIframe                      from './utils/isInIframe'
import isAttachedToDOM                 from './utils/isAttachedToDOM'
import isDetachedFromDOM               from './utils/isDetachedFromDOM'
import isCanvasSupported               from './utils/isCanvasSupported'
import isGamePadSupported              from './utils/isGamePadSupported'
import isIntersectionObserverSupported from './utils/isIntersectionObserverSupported'

import after    from './utils/after'
import query    from './utils/query'
import remove   from './utils/remove'
import before   from './utils/before'
import replace  from './utils/replace'
import prepend  from './utils/prepend'
import setValue from './utils/setValue'
import queryAll from './utils/queryAll'

import on       from './utils/on'
import off      from './utils/off'
import once     from './utils/once'
import dispatch from './utils/dispatch'

import getTop                    from './utils/getTop'
import getScrollTop              from './utils/getScrollTop'
import getElementsCount          from './utils/getElementsCount'
import getMouseMovement          from './utils/getMouseMovement'
import getScrollbarWidth         from './utils/getScrollbarWidth'
import getMouseOrTouchPosition   from './utils/getMouseOrTouchPosition'
import getMousePositionInElement from './utils/getMousePositionInElement'

import cutChildNodes from './utils/cutChildNodes'

import createStyle  from './utils/createStyle'
import createScript from './utils/createScript'

import inViewPort from './utils/inViewPort'

const DOM = {
  isInIframe,
  isCanvasSupported,
  isGamePadSupported,
  isIntersectionObserverSupported,

  cutChildNodes,

  setValue,
  remove,
  replace,
  before,
  prepend,
  after,

  query,
  queryAll,

  on,
  once,
  off,
  dispatch,

  getElementsCount,
  getScrollbarWidth,
  getScrollTop,
  getTop,
  getMouseOrTouchPosition,
  getMousePositionInElement,
  getMouseMovement,

  inViewPort,

  createStyle,
  createScript,

  /* eslint-disable @typescript-eslint/naming-convention */
  isAttachedToDOM,
  isDetachedFromDOM
  /* eslint-enable @typescript-eslint/naming-convention */
}

export default DOM
