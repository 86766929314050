import { is } from '@common/is/is'

import { Number } from '@common/Number/Number'

import getScrollTop from './getScrollTop'

/**
 * Az elem távolság az oldal tetejétől.
 * @param el - A vizsgált elem.
 */
export default function getTop (el: Element): number {
  const s        = window.getComputedStyle(el)
  const tmp      = s.getPropertyValue('top') // lehet üres string is
  const styleTop = Number.parseInt(tmp === '' ? '0' : tmp)

  const scrollTop = getScrollTop()

  const rect = el.getBoundingClientRect()

  return Math.max(rect.top + scrollTop, is.number(styleTop) ? styleTop : 0)
}
