import type { TRequestMethod } from '@common/http/definitions'
import { is } from '@common/is/is'

import type { TResult } from './getPostData'

/**
 * Visszaadja a request metódust, attól függően,
 * hogy volt-e előredefiniált,
 * vagy küldünk-e adatot a szervernek.
 * @param data   - Az adat, amit küldünk a szervernek.
 * @param method - Az előredefiniált metódus.
 */
export default function getMethod (data: TResult, method?: TRequestMethod): TRequestMethod {
  if (is.string(method)) {
    return method
  }

  if (is.null(data)) {
    return 'GET'
  }

  return 'POST'
}
