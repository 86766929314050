import { is } from '@common/is/is'

const enum ECheckbox {
  Checked   = 1,
  UnChecked = 0
}

/**
 * Visszaadja a node value értékét, már ha van neki.
 * @param node - A megadott node.
 */
export default function getValueFromNode (node: HTMLElement): number | string | null {
  let value: number | string | null = null

  if (is.instanceOf(node, HTMLInputElement)) {
    if (node.type === 'checkbox') {
      value = node.checked ? ECheckbox.Checked : ECheckbox.UnChecked
    }
    else if (is.defined(node.value)) {
      value = node.value
    }
    else {
      value = node.getAttribute('value')
    }
  }
  else if (is.instanceOf(node, HTMLSelectElement)) {
    if (is.defined(node.options[node.selectedIndex])) {
      // Itt muszáj getAttribute-al elkérni, különben a value nélküli option esetén a szöveget adná vissza.
      value = node.options[node.selectedIndex].getAttribute('value')
    }
  }
  else if (is.instanceOf(node, HTMLTextAreaElement)) {
    value = node.value
  }
  else if (node.hasAttribute('value')) {
    value = node.getAttribute('value')
  }
  // Ha gettere van a value-ra, akkor azt is próbáljuk meg.
  else {
    const tmp = is.property(node, 'value') ? node.value : null

    value = is.string(tmp) || is.number(tmp) ? tmp : null
  }

  return value
}
