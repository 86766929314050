const { times } = require('./times')
/**
 * @typedef {(i: number) => void} TCallback
 */

/**
 * For helyett használható segédfüggvény.
 * @param {TCallback} func - Meghívandó függvények.
 * @param {number} limit - Limit.
 * @param {number} init - Kiinduló érték.
 */
function sequence (func, limit, init = 0) {
  const arr = times(limit - init, (i) => i + init)

  for (const i of arr) {
    func(i)
  }
}

module.exports.sequence = sequence
