import getAllCookiesAsObject from './getAllCookiesAsObject'

/**
 * Visszaadja a cookie nevéhez tartozó értéket, vagy undefined.
 * @param cookie - A cookie string.
 * @param name   - A keresett cookie neve.
 */
export default function getCookieValueByName (cookie: string, name: string): string | undefined {
  const parts = getAllCookiesAsObject(cookie)
  const value = parts[name]

  return value
}
