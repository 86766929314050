/* eslint-disable unicorn/no-document-cookie -- Itt szükségünk van a document.cookie-ra. */

import hasCookieByName from './utils/hasCookieByName'

import BaseCookie from './BaseCookie'

export default class ClientCookie extends BaseCookie {
  /**
   * Egy cookie beállítása.
   * @param cookie - A cookie darabok.
   */
  public static set (cookie: string[]): void {
    document.cookie = BaseCookie.parse(cookie)
  }

  /**
   * Egy cookie törlése.
   * @param cookie - A cookie string.
   * @param path   - Melyik path-re vonatkozik?
   * @param domain - Melyik domain-re vonatkozik?
   */
  public static delete (cookie: string, path?: string, domain?: string): void {
    const cookieWithValue = `${ cookie }=`
    document.cookie = BaseCookie.clear(cookieWithValue, path, domain)
  }

  /**
   * Egy cookie elkérése név alapján.
   * @param name - A név, ami alapján elkérjük a cookie-t.
   */
  public static override get (name: string): string | undefined {
    return BaseCookie.get(name, document.cookie)
  }

  /**
   * Létezik az adott névvel cookie?
   * @param name - A név, ami alapján keressük a cookie-t.
   */
  public static has (name: string): boolean {
    return hasCookieByName(document.cookie, name)
  }

  /**
   * Visszaadja, hogy engedélyezve vannak-e a cookie-k vagy sem.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/cookieEnabled}
   */
  public static isEnabled (): boolean {
    // eslint-disable-next-line no-restricted-globals, no-restricted-properties
    return navigator.cookieEnabled
  }
}
