import { is } from '@common/is/is'

import languageHandler from './languageHandler'
import getFallbackLang from './getFallbackLang'
import localeHandler   from './localeHandler'
import transLog        from './transLog'
import getTrans from './getTrans'

/**
 * Ez akkor kell, ha nem akarjuk, hogy más nyelvből kapjunk fallback-et.
 * @param id   - Az id, ami alapján keresünk.
 * @param lang - Milyen nyelvben keresünk.
 * @param data - Az adatunk, amiben keresünk.
 */
export default function getTransRaw (
  id: string,
  lang = languageHandler.getLang(),
  data = localeHandler.getAllLocales()
): string | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let res: any = data[lang] // ebbe kerül az nyelvi object-ből az aktuális rész

  if (!res) {
    const fallbackLang = getFallbackLang(lang)

    transLog(`Nincs az adott nyelvhez fordítás: ${ lang }, fallbackLang: ${ fallbackLang }`)

    if (is.null(fallbackLang)) {
      return null
    }

    return getTrans(id, fallbackLang, data)
  }

  const parts = id.split('.') // [ 'my', 'nested', 'text', 'id' ]

  // Hagyományos for loop, hogy könnyen lehessen breakelni.
  for (const key of parts) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const value = res[key]

    // undefined vagy nem (object | string) VAGY üres string
    if (
      (
        !value &&
        !is.object(value) &&
        !is.string(value) // eslint-disable-line misc/typescript/no-never -- Ez itt valamiért never.
      ) ||
      (
        is.string(value) &&
        is.empty(value)
      )
    ) {
      // eslint-disable-next-line no-restricted-syntax
      transLog(`El van rontva az id: ${ id }, lang: ${ lang }, typeof value: ${ typeof value }, value: ${ value }`)

      res = null // hogy kint tudjunk nullra vizsgálni és az eddigi res értéket eltűntessük
      break
    }

    res = value // object vagy string
  }

  return res
}
