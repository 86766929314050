/**
 * Megpróbálja parszolni a megadott URL-t.
 * @param {unknown} url - Az ismeretlen paraméter.
 * @param {boolean} [safe=false] - Alapértelmezetten hibát dob, ha parse error van.
 * @throws Ha nem valid URL lett átadva, akkor hibát dob.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URL/URL}
 * @returns {URL} A parszolt URL.
 */
function parse (url, safe = false) {
  /** @type {URL} */
  // @ts-expect-error -- Nem kell a toJson.
  let parsedUrl = {
    hash: '',
    host: '',
    hostname: '',
    href: '',
    origin: '',
    password: '',
    pathname: '',
    port: '',
    protocol: '',
    search: '',
    // eslint-disable-next-line no-restricted-globals, n/prefer-global/url-search-params
    searchParams: new URLSearchParams(),
    username: ''
  }

  try {
    // @ts-expect-error Szeretnénk, hogy unknown-ként is befogadja.
    // eslint-disable-next-line no-restricted-globals, n/prefer-global/url -- Itt nem akarunk require-t használni, mert lehet browserben vagyunk.
    parsedUrl = new URL(url)

    return parsedUrl
  }
  catch (
    /** @type {any} */
    error
  ) {
    if (!safe) {
      throw new Error(error)
    }
  }

  return parsedUrl
}

module.exports.parse = parse
