/**
 * Index alapján kiveszi az elemet a tömbből.
 * Ez a függvény MUTABLE, tehát az eredeti tömböt változtatja meg.
 * @template T
 * @param {Array<T>} array - A tömb, ahonnan ki akarjuk venni az elemet.
 * @param {number} index - Az index, ami alapján.
 * @returns {Array<T>} A referenciatömb.
 */
function removeByIndex (array, index) {
  array.splice(index, 1)

  return array
}

module.exports.removeByIndex = removeByIndex
