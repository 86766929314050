import type { TRequestMethod } from '@common/http/definitions'
import type { TQuery } from '@common/Query/definitions'

import { URL } from '@common/URL/URL'
import { is } from '@common/is/is'

import type { TErrorCallback, TSuccessCallback } from '../definitions'

import { IS_ASYNC } from '../consts'

import makeOnReadyStateChange from './makeOnReadyStateChange'
import setRequestHeaders from './setRequestHeaders'

import type { TPostData } from './getPostData'
import getPostData from './getPostData'
import getMethod from './getMethod'

// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
type TResponseType = 'text' | 'json'

export interface IParams {
  readonly query?: TQuery
  readonly data?: TPostData
  readonly error?: TErrorCallback
  readonly success?: TSuccessCallback
  readonly method?: TRequestMethod
  readonly responseType?: TResponseType
  readonly needXrw?: boolean // X-Requested-With
  readonly withCredentials?: boolean
  readonly corsOrigin?: string
}

/**
 * Egyetlen XHR request példányosítása és felparaméterezése.
 * @param url    - Az url, ahova lőjjük a request-et.
 * @param params - A paraméterek, amik alapján beállítjuk az XHR-t.
 */
export default function xhrMaker (url: string, params: IParams): void {
  const xhr = new XMLHttpRequest()

  // https://stackoverflow.com/questions/34905828/xmlhttprequest-onreadystatechange-vs-addeventlistener
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  xhr.onreadystatechange = makeOnReadyStateChange(xhr, params.error, params.success)

  const postData = getPostData(params.data)
  const method   = getMethod(postData, params.method)

  // URL + query
  const urlWithQuery = URL.make(url, params.query)

  // --- OPEN

  xhr.open(method, urlWithQuery, IS_ASYNC) // nem támogatunk szinkron / blokkoló hívást

  // --- Ezeket az open után és a send előtt kell meghívni!

  xhr.responseType = params.responseType ?? 'text'

  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
  if (is.boolean(params.withCredentials)) {
    xhr.withCredentials = params.withCredentials
  }

  // A szükséges header beállítások
  const needXrw = is.defined(params.needXrw) ? params.needXrw : true
  setRequestHeaders(xhr, postData, needXrw, params.corsOrigin)

  // --- SEND, semmi más nem lehet.

  xhr.send(postData as any) // eslint-disable-line @typescript-eslint/no-explicit-any
}
