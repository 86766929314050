const { randomExcept } = require('../../Number/utils/randomExcept')

// TODO returns ReadonlyArray<numer>

/**
 * Véletlenszerűen választott egyedi számok.
 * @param {number} count - Mennyi számot kell generálni?
 * @param {number} min   - Minimum érték.
 * @param {number} max   - Maximum érték.
 * @returns {Array<number>} A generált egyedi számok.
 */
module.exports.randomUniqNumbers = function randomUniqNumbers (count, min, max) {
  // A `min` és `max` paraméterekből számolt maximális számok száma
  const maxCount = (max - min) + 1

  const validCount = maxCount <= count ? maxCount : count

  return Array.from({ length: validCount }).reduce((acc) => {
    const random = randomExcept(min, max, acc)

    return [ ...acc, random ]
  }, [])
}
