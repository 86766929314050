/**
 * Létrehoz `n` hosszú tömböt, amit az `iteratee` függvény tölt fel.
 * @template T
 * @param {number} n - Hány elemű tömb legyen?
 * @param {(num: number) => T} iteratee - A tömb elemeit ezzel a függvénnyel lehet legenerálni.
 * @returns {T[]} Az `iteratee` függvény által létrehozott tömb.
 */
function times (n, iteratee) {
  return Array.from({ length: n }, (_, i) => {
    return iteratee(i)
  })
}

module.exports.times = times
