/** @typedef {import('../../Query/definitions').TQuery} TQuery */

const { Query } = require('../../Query/Query')
const { is } = require('../../is/is')

/**
 * Egy URL összerakása query paraméterekkel.
 * @param {string} baseUrl - Az alap url.
 * @param {TQuery | string | null | undefined} [query] - A query, amit hozzá kell fűzni az alap url-hez.
 * @returns {string} Az összefűzött, használható url.
 */
module.exports.make = function make (baseUrl, query) {
  // Ha nincs query, akkor visszaadjuk úgy, ahogy van.
  if (!query) {
    return baseUrl
  }

  const [ url, path = '' ] = baseUrl.split('?')
  const values = path.split('&').filter((part) => !is.empty(part.trim()))

  const newValues = (
    is.string(query)
      ? query.replace('?', '')
      : Query.encode(query)
  ).split('&')

  // Ha a megadott paraméter string, akkor az elejéről lekapjuk a kérdőjelet, ha volt, egyébként encode.
  values.push(...newValues)

  const finalQuery = is.empty(values) ? '' : values.join('&')

  const finalUrl = `${ url }${ is.empty(finalQuery) ? '' : '?' }${ finalQuery }`

  return finalUrl
}
