const DIVIDER = 2

/**
 * A root top és left-jét ennek a segítségével számoljuk ki.
 * @param base - A window width-e, vagy height-je.
 * @param size - A scaledSize.
 */
export default function halfPx (base: number, size: number): string {
  return `${ ((base - size) / DIVIDER) }px`
}
