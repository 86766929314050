/**
 * Létrehoz egy link elemet, amihez beállítjuk a külső CSS fájl hivatkozást.
 * @param href - A source url.
 */
export default function createStyle (href: string): Promise<void> {
  return new Promise((resolve) => {
    const isAlreadyCreated = Array
      .from(document.querySelectorAll<HTMLLinkElement>('link[href]'))
      .find((link) => link.href.split('?')?.[0]?.includes(href))

    if (isAlreadyCreated) {
      resolve()

      return
    }

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = `${ href }?t=${ Date.now() }` // no-cache

    link.addEventListener('load', () => {
      resolve()
    })

    document.querySelector('head')?.appendChild(link)
  })
}
