/**
 * Megfordítja egy tömb vagy iterálható objektum elemeinek sorrendjét.
 * Ez a függvényt csak tömbökkel használható, Set, String és más Iterable-el nem.
 *
 * @template T Az elemek típusa a tömbben.
 * @param {ReadonlyArray<T> | T[]} collection - A megfordítandó gyűjtemény.
 * @returns {T[]} Egy új tömb a megfordított elemekkel.
 */
module.exports.reverse = function reverse (collection) {
  // eslint-disable-next-line no-restricted-syntax -- Az egyedüli hely, ahol lehet natív reverse.
  return [ ...collection ].reverse()
}
