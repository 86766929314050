const { make } = require('./utils/make')
const { parse } = require('./utils/parse')
const { createRoute } = require('./utils/createRoute')
const { isParsable } = require('./utils/isParsable')
const { toString } = require('./utils/toString')

const URL = {
  make,
  parse,
  createRoute,
  isParsable,
  toString
}

module.exports.URL = URL
