/**
 * @template {ReadonlyArray<any>} T
 * @typedef {T extends [ ...infer _Rest, infer TLast ] ? TLast : T extends readonly any[] ? T extends TNonEmptyArray<infer TItem> ? TItem : T[number] | undefined : never} TLastItem
 */

/**
 * Egy tömb utolsó elemének elkérése.
 * @template {ReadonlyArray<any>} T
 * @param {T} array - A tömb, aminek az utolsó elemét kérjük el.
 * @returns {TLastItem<T>} Az utolsó elem.
 */
module.exports.getLast = function getLast (array) {
  return array[array.length - 1]
}
