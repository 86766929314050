import { is } from '@common/is/is'

/**
 * Hány elemet tartalmaz az adott elem-ben.
 * @param parent - Ebbe keressük az elemek számát.
 */
export default function getElementsCount (parent: HTMLElement | Element | Document | null = document): number {
  if (is.null(parent)) {
    return 0
  }

  return parent.querySelectorAll(':scope > *').length
}
