/** Scroll top aktuális értéke. */
export default function getScrollTop (): number {
  const scrollTop = (
    // eslint-disable-next-line sonarjs/deprecation -- Maradjon így.
    window.pageYOffset ||
    window.scrollY ||
    document.documentElement.scrollTop
  ) ?? 0

  return scrollTop
}
