/**
 * Visszaadja a `array` tömb elemeit, kivéve a `exceptArray` elemeit.
 * @template T
 * @param {ReadonlyArray<T>} array - A tömbünk.
 * @param {ReadonlyArray<T>} exceptArray - Kivéve.
 * @returns {Array<T>} A filterezett tömb.
 */
module.exports.getItemsExcept = function getItemsExcept (array, exceptArray) {
  return array.filter((tmp) => !exceptArray.includes(tmp))
}
