import { is } from '@common/is/is'

import type { TLanguage } from '../definitions'

import getFallbackLang from './getFallbackLang'
import localeHandler   from './localeHandler'
import getTransRaw     from './getTransRaw'

/**
 * A 3. Paraméter csak a unit teszthez szükséges.
 * @param id   - Ami alapján keresünk.
 * @param lang - Milyen nyelvben keresünk.
 * @param data - Az adat, amiben keresünk.
 */
export default function getTrans (id: string, lang: TLanguage, data = localeHandler.getAllLocales()): string | null {
  let res = getTransRaw(id, lang, data)

  // Van fordítás, minden jó.
  if (!res) {
    const fallbackLang = getFallbackLang(lang)

    // Ha null, akkor nincs tovább, még a magyar fordítást is elrontottuk.
    // Egyébként ha van még fallback nyelvünk, próbáljunk rekurziót.
    res = is.null(fallbackLang)
      ? null
      : getTrans(id, fallbackLang, data)
  }

  return res
}
