const { is } = require('../../is/is')

/** @type {any} */
const NATIVE_ARRAY_PROTO = Array.prototype

/**
 * Rendezés az eredeti tömb módosítása nélkül.
 * @template T
 * @param {readonly T[]} param - A megadott tömb paraméter.
 * @param {(a: T, b: T) => number} [compareCallback] - A rendezéshez használható callback.
 * @returns {T[]} Az új, rendezett tömb.
 */
module.exports.sort = function sort (param, compareCallback) {
  // Ha van natív támogatás, akkor használjuk azt.
  if (is.function(NATIVE_ARRAY_PROTO.toSorted)) {
    // @ts-expect-error Jah.
    return param.toSorted(compareCallback)
  }

  // eslint-disable-next-line no-restricted-syntax -- Really?
  const copy = [ ...param ].sort(compareCallback)

  return copy
}
