/* eslint-disable no-restricted-properties -- scrollTo és scrollIntoView */

import DOM from '../DOM/DOM'
import { is } from '../is/is'

// https://github.com/wessberg/scroll-behavior-polyfill
if (!('scrollBehavior' in document.documentElement.style)) {
  DOM.createScript('https://cdn.jsdelivr.net/npm/scroll-behavior-polyfill@2.0.13/dist/index.min.js')
}

const ZERO = 0

type TScrollBehavior = 'auto' | 'smooth' | 'instant'

const scroll = {
  /**
   * Egy adott Y pozicióra scrollozás.
   * @param top      - Top pozició.
   * @param behavior - Hogyan történjen meg a scrollozás.
   */
  toY: (top = ZERO, behavior: TScrollBehavior = 'smooth'): void => {
    if (window.scrollTo) {
      window.scrollTo({ top, behavior })
    }
    else {
      document.documentElement.scrollTop = top
    }
  },

  /**
   * Adott x pozícióra scrollozás.
   * @param left     - A megadott x pozíció.
   * @param behavior - Hogyan történjen meg a scrollozás.
   */
  toX: (left = ZERO, behavior: TScrollBehavior = 'smooth'): void => {
    if (window.scrollTo) {
      window.scrollTo({ left, behavior })
    }
    else {
      document.documentElement.scrollLeft = left
    }
  },

  /**
   * Egy konkrét HTML elemhez navigálás.
   * @param el      - Az adott elem.
   * @param padding - Padding, amennyivel feljebb navigálunk.
   */
  intoView: (el: Element, padding = ZERO): void => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    if (el.scrollIntoView && padding === ZERO) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
    else {
      const tmp = DOM.getTop(el)

      const top = tmp - padding
      scroll.toY(top)
    }
  },

  /** Az oldal tetejére navigálás. */
  toTop: (): void => {
    scroll.toY()
  },

  /**
   * Az adott ID-jú elemhez navigálás, ha létezik.
   * @param id - Az ID, ahova navigálni szeretnénk.
   */
  toId: (id: string): void => {
    try {
      // Példa rossz query-re:
      // Uncaught (in promise) DOMException: Document.querySelector: '#csomagok?email=joskapista%2Bdev5%40balazs-diak.hu' is not a valid selector
      const el = document.querySelector(`#${ id }`)

      if (is.instanceOf(el, HTMLElement)) {
        scroll.intoView(el)
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('[SCROLL][toId]', error)
    }
  }
}

export default scroll
