/* eslint-disable no-control-regex -- Itt mi pontosan így akarjuk írni a regexp-et. */

const { getRegex } = require('./utils/getRegex')
const { isRegex }  = require('./utils/isRegex')

/**
 * @see {@link https://regex101.com/}
 */
const RX = {
  YEAR: /(1|2)\d{3}/g,
  NON_NUMBERS: /\D/g,
  AMPERSAND: /&/g,

  WHITESPACE: /\s+/g,
  NO_BREAK_SPACE: /\u00A0/g,
  MULTIPLE_WHITESPACE: / {2,}/g,
  MULTIPLE_BR_TAGS: /(<br\s*\/?>\s*){2,}/g,
  DASH: /-+/g,
  LINE_BREAKS: /(\r\n|\n\r|\r|\n)/g,
  EMPTY_LINES: /(^[\t ]*\n)/gm,
  QUOTES: /["'`]/g,
  TAB: /\t/g,

  // eslint-disable-next-line @stylistic/js/max-len -- Ez van, ez ilyen hosszú.
  EMAIL: /(?:[\d!#$%&'*+\-/=?^_`a-z{|}~]+(?:\.[\d!#$%&'*+\-/=?^_`a-z{|}~]+)*|"(?:[\u0001-\u0008\v\f\u000E-\u001F\u0021-\u0052\u0053-\u007F]|\\[\u0001-\u0009\v\f\u000E-\u007F])*")@(?:(?:[\da-z](?:[\d\-a-z]*[\da-z])?\.)+[\da-z](?:[\d\-a-z]*[\da-z])?|\[(?:(2([0-4]\d|5[0-5])|1\d{2}|[1-9]?\d)\.){3}(?:(2([0-4]\d|5[0-5])|1\d{2}|[1-9]?\d)|[\d\-a-z]*[\da-z]:(?:[\u0001-\u0008\v\f\u000E-\u001F\u0021-\u0052\u0053-\u007F]|\\[\u0001-\u0009\v\f\u000E-\u007F])+)\])/,
  BOT: /baidu|bing|bot|google|msn|slurp|teoma|yandex/i,
  HTML: /(<([^>]+)>)/g,

  PATH: /^[a-z]+(\/[a-z]+)*$/i,
  ABC: /^[a-záéíóöüőű]+$/i,
  /**
   * @example
   * 1
   * 1.1
   * 1.1.1
   */
  SEMVER: /^(\d+\.)?(\d+\.)?(\*|\d+)$/,

  // https://regexr.com/39s32
  // eslint-disable-next-line @stylistic/js/max-len -- Ez van, ez ilyen hosszú.
  SEMVER_STRICT: /^(((\d+)\.(\d+)\.(\d+)(?:-([\d\-A-Za-z]+(?:\.[\d\-A-Za-z]+)*))?)(?:\+([\d\-A-Za-z]+(?:\.[\d\-A-Za-z]+)*))?)$/gm,
  HEX_COLOR_HASH: /(^#[\da-f]{6}$)|(^#[\da-f]{3}$)/i,
  FILE_EXTENSION: /\.[^./]+$/,
  HTTP_PROTOCOL: /(^\w+:|^)\/\//,

  HTTP_WITH_DOMAIN: /^https?:\/\/[-0-9a-z]+(\.[-0-9a-z]+)+(:\d{2,5})?/i,

  TRAILING_FORWARD_SLASH: /\/$/,
  FORWARD_SLASH: /\//g,
  SLASH: /(\\|\/)/g,
  BACKSLASH: /\\/g,
  DBL_BACKSLASH: /\\\\/g,

  BRACKET: /(\(|\))/g,

  ANSI: /[\u001B\u009B][#();?[]*(?:\d{1,4}(?:;\d{0,4})*)?[\d<=>A-ORZcf-nqry]/g,

  CAMELCASE: /^[a-z]+([A-Z][a-z]+)*$/,

  SHA_256: /^[\da-f]{64}$/gi,
  SHA_1: /^[\da-f]{5,40}$/gi,

  DATE: /^\d{4}-\d{2}-\d{2}$/,
  ISO_DATE: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
  ISO_DATE_WITH_T: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,

  UGYESEDNI_SERIAL: /^BD-TO0\d{3}$/,

  BASE64IMAGE: /^data:image\/\w+;base64,/,

  IPV4: /^(?:(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/,

  IPV6: /^((([\dA-Fa-f]{1,4}:){1,6}:)|(([\dA-Fa-f]{1,4}:){7}))([\dA-Fa-f]{1,4})$/,

  LT: /&lt;/g,
  GT: /&gt;/g,

  // @ts-ignore -- Csak ES6 alatt érhető el, de nem gond, mert csak backendes projecteknél használjuk.
  // eslint-disable-next-line @stylistic/js/max-len
  UNICODE_EMOJI: /[\u{2B50}\u{2B55}\u{1F004}\u{1F0CF}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}]|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}/gu,

  isRegex,
  getRegex
}

module.exports.RX = RX
